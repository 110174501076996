.team-list-section {
    width: 100%;
    height: calc(100% - 15px);
    position: relative;

    .members-wrapper {
        overflow-y: scroll;
        padding: 0 15px;
        margin: 13px 4px 13px 0;
        height: 460px;

        &::-webkit-scrollbar {
            display: none;
        }

        .members-list {
            width: 100%;
            padding-bottom: 95px;

            &.with-fill {
                padding-bottom: 0;
            }

            &.with-fill-min {
                padding-bottom: 10px;
            }
        }

        .fill-your-team {
            //margin-bottom: 95px;
        }
    }

    .share-hex-back {
        position: absolute;
        right: 0;
        bottom: 0;

        width: 100%;
        height: 82px;

        background: linear-gradient(
            0deg,
            rgba(0, 0, 0, 0.71) 0%,
            rgba(0, 0, 0, 0) 100%
        );

        .share-hex {
            position: absolute;
            right: 13px;
            bottom: 10px;
        }
    }
}
