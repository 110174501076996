.red-tab-popup {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    .red-tab-item {
        position: relative;
        width: 184px;
        height: 328px;
        .red-tab-bg {
            width: 100%;
            height: 100%;
            background-image: url(../../../assets/images/popups/store/store_bg.svg);
            background-repeat: no-repeat;
            position: relative;
            .red-tab-chip {
                position: absolute;
                left: 0;
                right: 0;
                margin: auto;
                top: -25px;
            }
            .red-tab-discount-img {
                position: absolute;

                right: 10px;

                top: -25px;
            }
            .red-tab-chip-block {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                padding-top: 25px;
                .store-discount {
                    .red-tab-discount-img {
                        position: absolute;
                        top: -30px;
                        left: -30px;
                        z-index: 1;
                    }
                }
                .content-img {
                    left: -10px;
                    width: 215px;
                }
                .red-tab-text-block {
                    position: absolute;
                    right: 0px;
                    z-index: 1;
                    .red-tab-after-block {
                        .red-tab-text-after {
                            font-size: 16px;
                            color: var(--mainColor2);
                            font-family: 'MardotoBlack';
                            padding-top: 10px;
                            text-transform: uppercase;
                            position: absolute;
                            right: 20px;
                        }
                        .red-tab-border-block {
                            border: 1px solid var(--mainColor2);
                            position: absolute;
                            right: 20px;
                            top: 17px;
                            width: 40px;
                        }
                    }
                    .red-tab-before-block {
                        position: relative;
                        top: 20px;
                        right: 10px;
                    }
                }
                img {
                    position: absolute;
                    bottom: 40px;
                    left: -30px;
                    &:first-child {
                        left: 0;
                    }
                }
                .chip-1000 {
                    left: -15px;
                }
                .red-tab-buy-chip {
                    display: flex;
                    .red-tab-buy-chip-block {
                        display: flex;
                        p {
                            color: var(--mainColor2);
                            font-size: 15px;
                        }
                    }
                }
                .red-tab-text {
                    font-size: 24px;
                    color: var(--mainColor2);
                    font-family: 'MardotoBlack';
                    padding-top: 10px;
                    text-transform: uppercase;
                }
                .red-tab-item-buy-now {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 0 14px;
                    width: 174px;
                    height: 42px;
                    border-radius: 6px;
                    background: linear-gradient(#fbdf4c 0%, #f6b72b 100%);
                    position: absolute;
                    bottom: 20px;
                    cursor: pointer;
                    p,
                    span {
                        color: #5e0000;
                        font-size: 17px;
                        font-family: 'MardotoBlack';
                        text-transform: uppercase;
                        z-index: 1;
                    }
                    span {
                        font-size: 19px;
                    }
                }
                .button-layer {
                    position: absolute;
                    height: 19px;
                    width: 174px;
                    background: rgba(255, 122, 122, 0.12);
                    bottom: 20px;
                }
            }
        }
    }
}
