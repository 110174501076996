.team-info-section {
    background: #00000066;
    box-shadow: 0 3px 6px #00000029;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    height: 100%;
    align-items: center;
    margin-top: 15px;

    .main-tab-changer {
        .changer-tab {
            .main-first {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0 15px;

                .players-info {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;
                    justify-content: center;
                }
            }

            .main-second {
                display: flex;
                height: 100%;
                width: 100%;
                align-items: center;
                justify-content: space-between;
                padding: 0 15px;

                .days-left {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    img {
                        width: 35px;
                        height: 38px;
                    }

                    .day-info {
                        display: flex;
                        margin-left: 5px;

                        &>.standard-label {
                            line-height: 26px;
                        }

                        .new-line {
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            margin-left: 3px;
                        }
                    }
                }
            }
        }
    }

}
