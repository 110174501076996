.edit-icon {
    width: 72px;
    height: 72px;
    border-radius: 50%;
    background: #1F2E0D;
    position: relative;
    cursor: pointer;

    .edit-icon-inner {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background: linear-gradient(to right, #516015 0%, #35442C 100%);
        position: absolute;
        top: 11px;
        left: 11px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}