.user-order,
.user-order-capout {
    width: 66px;
    height: 31px;
    background: var(--mainColor2);
    border-radius: 15px;
    position: absolute;
    top: -35px;
    left: 95px;

    &-value {
        position: relative;
        width: 27px;
        left: 2px;
        top: -3px;
        height: 27px;
        margin-top: 5px;
        margin-bottom: 5px;
        border-radius: 50%;
        background: #e2e2e2;
        img {
            position: absolute;
            top: 0;
            left: -1px;
            right: 0;
            bottom: 0;
            margin: auto;
            width: 18px;
        }
    }
    span {
        position: absolute;
        left: 35px;
        top: 4px;
        font-size: 19px;
        font-weight: 700;
        color: var(--mainColor26);
    }
    .capout-value {
        left: 68px;
        font-size: 19px;
        top: 4px;
    }
}

.user-order-4 {
    left: -25px;
    top: -40px;
    .user-order-value {
        left: 2px;
        top: -3px;
    }
}
.user-order-3 {
    left: -90px;
    top: 90px;

    span {
        left: 36px;
        top: 4px;
    }
}

.user-order-capout {
    width: 100px;
    &.user-order-3 {
        left: -110px;
        top: 97px;
    }
}
