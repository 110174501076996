.chip-reward-banner {
    width: 100%;
    height: 49px;
    background: linear-gradient(to right, #516015 0%, #35442c 100%);

    position: relative;

    .inner-wrapper {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        top: -15px;
        left: 0;
        right: 0;
        margin: auto;

        .reward-chips {
            width: 64px;
            height: 64px;
        }

        .reward-text {
            display: flex;
            flex-direction: column;
            text-align: left;
            padding-top: 20px;

            .reward-info {
                text-transform: uppercase;
                font-family: Mardoto;
                font-size: 14px;
                font-weight: 200;
                color: var(--cColor);
                text-shadow: 0 3px 6px #00000029;
            }
        }
    }
}
