.top-panel {
    width: 100%;
    height: 80px;
    background: var(--bColor);
    box-shadow: 0 3px 50px #00000066;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    box-sizing: border-box;

    .panel-align {
        display: flex;
    }

    .first-col {
        display: flex;
        align-items: center;
        cursor: pointer;

        .team-info {
            display: flex;
            text-align: left;
            flex-direction: column;
            margin-left: 25px;
        }
    }

    .level-info {
        display: flex;
        align-items: center;
        justify-content: center;

        .league {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 250px;

            .info {
                height: 75px;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: center;
                margin-left: 5px;

                .standard-label {
                    padding-left: 5px;
                }

                .standard-fill-bar {
                    margin-top: 5px;
                }
            }
        }

        .battles {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 25px;

            &.grayscale {
                filter: grayscale(1);
            }

            .info {
                width: 170px;
                height: 75px;
                display: flex;
                align-items: center;
                position: relative;

                .battles-info,
                .stars-info {
                    position: absolute;
                    left: -100px;
                    opacity: 0;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: center;

                    transition: left 0.3s linear, opacity 0.3s linear;

                    &.active {
                        left: 0;
                        opacity: 1;
                    }
                }

                .stars-info {
                    padding-left: 5px;
                }
            }
        }
    }

    .last-col {
        display: flex;
        align-items: center;
        justify-content: center;

        &.grayscale {
            filter: grayscale(1);
        }

        .team-button {
            margin-left: 15px;
        }

        .vertical-line {
            height: 40px;
            width: 1px;
            background: #ffffff1a;
            margin-left: 25px;
        }

        .home-icon {
            width: 31px;
            height: 31px;
            margin-left: 23px;
            cursor: pointer;
        }
    }
}
