.more-info-section {
    display: flex;
    width: 100%;
    height: 100%;
    .left-side {
        .top-text-info {
            display: flex;
            justify-content: space-between;
            margin-top: 20px;
            padding: 0 20px;
            box-sizing: border-box;

            .left-text-info {
                display: flex;
                flex-direction: column;
                text-align: left;
            }

            .right-text-info {
                display: flex;
                flex-direction: column;
                text-align: right;

                .with-icon {
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;

                    .calendar-icon {
                        width: 10px;
                        height: 10px;
                        margin-right: 5px;
                    }
                }
            }
        }

        .info-shield {
            width: 350px;
            height: 300px;
            display: flex;
            justify-content: center;
            position: relative;
            margin-top: 40px;

            .edit-icon {
                position: absolute;
                right: 0;
                bottom: 0;
            }
        }

        .gradient-lines {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: 200px;
            .gradient-line {
                margin-top: 20px;
            }
        }
    }

    .right-side {
        .top-tabs {
            display: flex;
            align-items: center;
            border-bottom: 1px solid #ffffff1a;

            .top-tabs-floating {
                padding-left: 30px;
                width: 100%;
            }

            .top-tabs-close {
                width: 74px;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-left: 1px solid #ffffff1a;

                .close-button {
                    margin: 0 20px;
                }
            }
        }

        .more-info-content {
            padding-left: 30px;
            width: 100%;
            height: calc(100% - 80px);
            box-sizing: border-box;

            .statistics-content {
                width: 100%;
                text-align: left;
                margin-top: 30px;

                .top-info {
                    width: 100%;
                    border-bottom: 1px solid #ffffff1a;

                    .statistics-info {
                        margin: 0;
                        width: 420px;
                        font-family: Mardoto;
                        font-size: 16px;
                        font-weight: 200;
                        color: var(--cColor);
                        padding-bottom: 20px;
                    }
                }

                .statistic-lines {
                    display: flex;
                    flex-direction: column;
                    margin-top: 50px;

                    .statistic-line {
                        height: 107px;
                        border-bottom: 1px solid #ffffff1a;
                        display: flex;
                        align-items: center;

                        .hex-img {
                            width: 65px;
                            height: 69px;
                        }

                        .battle-data {
                            width: 100%;
                            padding-right: 30px;
                            box-sizing: border-box;
                            display: flex;
                            flex-direction: column;
                            margin-left: 30px;

                            .mini-infos {
                                width: 100%;
                                display: flex;
                                margin-top: 10px;

                                .mini-info {
                                    text-align: left;
                                    display: flex;
                                    flex-direction: column;
                                    justify-content: center;

                                    &:last-child {
                                        .main-info-top {
                                            text-align: right;
                                            justify-content: flex-end;
                                        }
                                    }

                                    .info-wrapper {
                                        display: flex;
                                        flex-direction: column;

                                        .secondary-label {
                                            white-space: nowrap;
                                        }
                                    }

                                    .mini-info-top {
                                        display: flex;
                                        align-items: center;

                                        .mini-info-icon {
                                            width: 14px;
                                            height: 14px;
                                        }
                                    }

                                    &.long {
                                        display: flex;
                                        align-items: center;
                                        justify-content: space-between;
                                        padding-left: 20px;
                                        margin-left: 20px;
                                        border-left: 1px solid #ffffff1a;
                                        flex-direction: row;
                                        width: 100%;

                                        .info-wrapper:last-child {
                                            align-items: flex-end;
                                        }
                                    }
                                }
                            }
                        }

                        .stars-data {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            margin-left: 30px;
                            width: 100%;
                            padding-right: 30px;
                            box-sizing: border-box;

                            .stars-text {
                                font-family: Mardoto;
                                font-size: 16px;
                                font-weight: 200;
                                color: var(--cColor);
                                width: 210px;
                            }

                            .gained-stars {
                                display: flex;
                                flex-direction: column;
                                justify-content: flex-end;
                                text-align: right;

                                .top-line {
                                    display: flex;
                                    align-items: center;
                                    justify-content: flex-end;

                                    .top-line-icon {
                                        width: 14px;
                                        height: 14px;
                                    }
                                }
                            }
                        }
                    }
                }

                ._3d-button {
                    float: right;
                    margin-top: 70px;
                    padding: 0;
                    margin-right: 30px;
                }
            }

            .conditions-content {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 100%;
                width: 100%;
                flex-direction: column;
                .level-win-rate {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: calc(100% - 82px);

                    .condition-wrapper {
                        text-align: left;
                        width: 196px;
                        margin-left: 50px;

                        &:first-child {
                            margin-left: 0;
                        }

                        .standard-textbox {
                            margin-top: 15px;
                            text-align: left;
                            padding-left: 20px;
                            box-sizing: border-box;
                        }

                        .conditions-error-wrapper {
                            &.without-error {
                                .error-banner {
                                    background: none;
                                    border: none;
                                }
                            }

                            .error-banner {
                                margin-top: 5px;
                                height: 34px;
                                padding: 0 10px;
                                span {
                                    font-size: 12px;
                                }
                            }
                        }
                    }
                }

                .save-section {
                    display: flex;
                    justify-content: flex-end;
                    text-align: right;
                    align-items: center;
                    width: 100%;
                    padding-right: 30px;
                    box-sizing: border-box;

                    p {
                        font-family: Mardoto;
                        font-size: 16px;
                        font-weight: 200;
                        color: var(--cColor);
                        width: 280px;
                        margin-right: 20px;
                    }

                    ._3d-button {
                        padding: 0;
                    }
                }

                .not-leader-more-info {
                    height: 100%;
                    width: 100%;
                    text-align: left;
                    position: relative;
                    padding-top: 30px;

                    .support-text {
                        font-family: Mardoto;
                        font-size: 16px;
                        font-weight: 200;
                        color: var(--cColor);
                        width: 370px;
                    }

                    .gradient-lines {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        margin-top: 80px;

                        .gradient-line .left-side-image {
                            width: 65px;
                            height: 69px;
                        }
                    }

                    .share-hex {
                        position: absolute;
                        right: 30px;
                        bottom: 30px;
                    }
                }
            }
        }
    }
}
