.text-tab-changer {
    height: 46px;
    width: 100%;
    background: var(--qColor);
    border-radius: 23px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    .detached {
        top: 0;
        position: absolute;
        height: 100%;
        border-radius: 23px;
        transition: left 0.2s ease-in-out;
    }

    .changer-tab {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        z-index: 1;
        cursor: pointer;

        span {
            text-shadow: 0 3px 6px #00000057;
        }
    }
}
