.say-button {
    height: 50px;
    width: 110px;
    background: var(--mainColor7);
    border-radius: 10px;
    border: 1px solid var(--mainColor1);
    cursor: pointer;
    z-index: 1;
    &:hover {
        background: var(--mainColor1);
        cursor: pointer;
        span {
            color: #000;
        }
    }
    span {
        font-size: 20px;
        font-weight: 700;
        color: var(--mainColor1);
    }
}
