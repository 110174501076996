.standard_slider {
    width: 100%;
    height: 76px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    overflow: hidden;

    .arrow-btn {
        margin-top: 18px;
        width: 20px;
        height: 20px;
        cursor: pointer;
    }

    .slides_wrapper {
        height: 76px;
        width: calc(100% - 40px);

        overflow: hidden;

        .slides {
            height: 76px;
            transition: margin-left 0.3s ease-in-out;

            .slide {
                float: left;
                width: 27px;
                height: 76px;
                margin-left: 7px;
                margin-top: 16px;
                position: relative;
                overflow: hidden;
                cursor: pointer;

                transition: width 0.3s ease-in-out, height 0.3s ease-in-out,
                    margin-top 0.3s ease-in-out;

                &.before_active,
                &.after_active {
                    width: 44px;
                    height: 41px;
                    margin-top: 8px;
                }

                &.active {
                    width: 73px;
                    height: 69px;
                    margin-top: 4px;
                }

                img {
                    width: 100%;
                }
            }
        }
    }
}
