.best_score {
    position: absolute;
    right: -70px;
    top: -56px;
    .best_third {
        width: 196px;
        height: 196px;
        background: rgba(203, 146, 54, 0.1);
        border-radius: 50%;

        display: flex;
        align-items: center;
        justify-content: center;

        .best_second {
            width: 146px;
            height: 146px;
            background: rgba(203, 146, 54, 0.3);
            border-radius: 50%;
            z-index: 2;

            display: flex;
            align-items: center;
            justify-content: center;

            .best_yellow {
                width: 100px;
                height: 100px;
                background: #f1b748;
                border-radius: 50%;
                z-index: 3;
                .score_end {
                    text-align: center;
                    width: 80px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    margin: 0 auto;
                    padding-top: 30px;
                    .best_score_title {
                        font-weight: 500;
                        font-size: 11px;
                        color: var(--mainColor2);
                        display: block;
                        width: 50px;
                    }
                    .best_score_number {
                        font-size: 20px;
                        font-weight: 700;
                        color: var(--mainColor2);
                    }
                }
            }
        }
    }
}
