.right-section {
    display: flex;
    flex-direction: column;
    place-self: flex-end;
    justify-self: flex-end;
    position: relative;
    background: rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    padding-top: 15px;
    height: 150px;
    width: 758px;
    // .badge-bottom-animate {
    //     .badge-bottom-section {
    //         animation: scaleBottom 1s ease-in-out;
    //         animation-fill-mode: both;
    //     }
    // }
    .badge-images {
        width: 100%;
        display: flex;
        justify-content: space-around;
        margin-bottom: 10px;
        img {
            margin: 0 15px;
        }
    }
}
// @keyframes scaleBottom {
//     0% {
//         transform: scale(1);
//     }
//     50% {
//         transform: scale(0.7);
//     }
//     100% {
//         transform: scale(1);
//     }
// }
