.lobby_name {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;

    .lobby_text {
        text-transform: uppercase;
        font-size: 19px;
        font-weight: 700;
        color: var(--mainColor2);
        position: relative;
    }
    img {
        margin-bottom: 10px;
    }
}
