.dots-tab-changer {
    padding: 10px;
    border-radius: 20px;
    background: var(--qColor);
    display: flex;
    align-items: center;
    justify-content: center;

    .dot {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: var(--cColor);
        opacity: 0.3;
        cursor: pointer;
        margin-left: 8px;

        &.active,
        &:hover {
            opacity: 1;
        }

        &:first-child {
            margin-left: 0;
        }
    }
}
