.notifications-circle {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    border: 1px solid #c86d70;
    background: linear-gradient(270deg, #8c1d25 0%, #a11f25 100%);
    position: absolute;
    left: -10px;
    bottom: -10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    transition: width 0.1s linear, height 0.1s linear,
        left 0.1s linear, right 0.1s linear, bottom 0.1s linear;

    &:hover {
        width: 86px;
        height: 86px;

        left: -13px;
        bottom: -13px;
    }

    &.right {
        left: unset;
        right: -10px;

        &:hover {
            width: 86px;
            height: 86px;

            right: -13px;
            bottom: -13px;
        }
    }


}
