.chat-section {
    width: 100%;
    height: 500px;
    box-sizing: border-box;
    padding-right: 28px;

    .messages-wrapper {
        width: 100%;
        height: calc(100% - 64px);
        padding-left: 20px;
        overflow-y: auto;
        overflow-x: hidden;
        box-sizing: border-box;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    .write-message-wrapper {
        width: 100%;
        height: 64px;
        border-top: 1px solid var(--uColor);
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: 20px;
        box-sizing: border-box;

        form {
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: center;
        }

        .message-input {
            width: 100%;
            background: none;
            border: none;
            font: Condensed Bold 16px 'Mardoto';
            outline: none;
            color: var(--cColor);

            transition: padding-left 0.3s ease-in-out;
        }

        img {
            cursor: pointer;
        }

        &.notification-exists {
            .message-input {
                padding-left: 72px;
            }
        }
    }
}
