.leave-popup {
    min-width: 528px;
    .close-leave {
        position: absolute;
        right: -15px;
        top: -15px;
    }
    .standard-label {
        padding-bottom: 10px;
    }
    .info {
        width: 325px;
        color: var(--cColor);
        text-align: center;
        font-size: 16px;
        font-weight: 200;
        padding-bottom: 20px;
    }
    .buttons-wrapper {
        display: flex;
        height: 46px;
        ._3d-button {
            padding: 0;
            margin-left: 20px;
            &:first-child {
                margin-left: 0;
            }
        }
    }
}
