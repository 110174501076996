.user_score_1,
.user_score_4 {
    position: absolute;
    border-radius: 4px;
    background: #54b76d;
    width: 25px;
    height: 25px;
    span {
        display: block;
        text-align: center;
        line-height: 26px;
        color: var(--mainColor6);
        font-weight: 700;
        font-size: 14px;
    }
}
.user_score_1 {
    left: 625px;
    bottom: 0px;
}
.user_score_4 {
    right: 50px;
    bottom: -100px;
}
