.universal-shield {
    position: relative;
    width: 260px;
    height: 246px;

    .ribbon-back {
        width: 260px;
        position: absolute;
        left: 0;
        bottom: 40px;
    }

    .shield-wrapper {
        position: absolute;
        left: 25px;
        top: 0;
        width: 210px;
        height: 246px;
        .shield-img {
            width: 210px;
            height: 246px;
        }

        .shield-icon {
            width: 48px;
            height: 48px;
            position: absolute;
            filter: invert(85%);

            &.shield-icon-1 {
                left: 33px;
                top: 53px;
            }

            &.shield-icon-2 {
                right: 33px;
                top: 53px;
            }

            &.shield-icon-3 {
                bottom: 50px;
                left: 0;
                right: 0;
                margin: auto;
            }
        }

        .highlighted-image {
            position: absolute;
            left: 0;
            top: 0;
            width: 210px;
            height: 246px;
        }

        .click_highlight {
            width: 80px;
            height: 80px;
            border-radius: 50%;
            position: absolute;
            cursor: pointer;

            &.top_left {
                left: 18px;
                top: 40px;
            }

            &.top_right {
                right: 18px;
                top: 40px;
            }

            &.bottom {
                bottom: 30px;
                left: 0;
                right: 0;
                margin: auto;
            }
        }
    }


    .ribbon-wrapper {
        position: absolute;
        left: 22px;
        bottom: 15px;
        width: 214px;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;

        .ribbon-front {
            width: 214px;
            height: 48px;
        }

        .curved-shield-text-wrapper {
            position: absolute;
            left: 0;
            right: 0;
            margin: auto;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            text-shadow: 2px 3px 5px  rgba(0,0,0,.3);

            &.in-lobby {
                letter-spacing: 5px;
            }
        }


    }
}
