.universal-table {
    width: 100%;

    table {
        width: 100%;
        border-spacing: 0;
        tr {
            td {
                padding: 9px 10px;
                border-right: 1px solid var(--uColor);
                border-bottom: 1px solid var(--uColor);

                &:last-child {
                    border-right: 0;
                }
            }

            &:first-child {
                td {
                    border-top: 1px solid var(--uColor);
                }
            }

            &.empty {
                td {
                    border-bottom: 0;
                }
            }
        }
    }
}
