.tour-partisipation {
    display: flex;
    margin-top: 50px;
    border-bottom: 1px solid rgba(52, 44, 34, 0.4);
    margin-right: 30px;
    &:last-child {
        border-bottom: 1px solid transparent;
    }

    .tour-partisipation-text {
        margin-left: 23px;
        margin-bottom: 50px;
        span {
            font-size: 18px;
            color: var(--mainColor2);
            font-weight: 700;
        }
        p {
            font-size: 15px;
            color: var(--mainColor2);
            font-weight: 500;
            padding-top: 20px;
        }
    }
}
