.user-bot {
    background: #57bb70;
    width: 90px;
    height: 90px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: -2px;
    .spector-user {
        position: absolute;
        width: 90px;
        bottom: 27px;
        left: 10px;
        transform: scale(1);
    }
    .user-bot-img {
        width: 76px;
        height: 76px;
        border-radius: 50%;
        margin: 0 auto;
        border: 5px solid #88db9d;
        position: absolute;
        z-index: 5;

        img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
        }
    }
}
