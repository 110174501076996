.star {
  position: relative;

  .star_type {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}
