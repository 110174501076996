.shield-info {
    position: relative;
    width: 77px;
    height: 91px;
    img {
        position: absolute;
        bottom: -10px;
        left: 0;
        width: 77px;
        height: 91px;
    }
}