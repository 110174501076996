.not-enough-red {
    width: 856px;
    height: 440px;
    position: relative;
    top: -50px;
    .close-icon {
        position: absolute;
        right: 12px;
        top: 12px;
        cursor: pointer;
    }
    .not-enough-block {
        display: flex;
        margin-left: 70px;
        margin-top: 60px;
        .red-block {
            img {
                width: 300px;
            }
        }
        .red-block-text {
            text-transform: uppercase;
            color: var(--mainColor2);
            font-size: 26px;
            font-family: 'Roboto-Slab';
            font-weight: 700;
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-left: 30px;
            .red-text-first {
                font-family: 'Roboto-Slab';
            }
            .red-text-second {
                font-size: 54px;
                color: #ff0000;
                font-family: 'Roboto-Slab';
                margin-top: -5px;
                margin-bottom: -5px;
            }
            .red-text-third {
                font-family: 'Roboto-Slab';
            }
        }
    }
    .vip_block_button {
        display: flex;
        right: 20px;
        bottom: 20px;
        position: absolute;
        .vip-info-popup {
            height: 46px;
            .button-tree {
                height: 46px;
                button {
                    background: #500000;
                    margin-right: 15px;
                    span {
                        font-size: 15px;
                    }
                }
                .button-tree-under {
                    background: #400003;
                    position: absolute;
                    bottom: -6px;
                }
            }
        }
        .vip-buy-now {
            height: 46px;
            span {
                font-size: 15px;
            }
            .button-tree-under {
                position: absolute;
                bottom: -6px;
            }
        }
    }
}
