.start-battle {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    z-index: 5;

    .team-button {
        position: absolute;
        top: 18px;
        right: 99px;
    }

    .circle-animating-wrapper {
        position: absolute;
        top: 0;
        right: 100px;
        width: 0;
        height: 0;
        overflow: hidden;

        transition: width 0.2s linear, height 0.2s linear,
            right 0.2s linear;

        &.animated {
            width: 100%;
            height: 100%;
            right: 0;
        }
    }

    .start-battle-inner {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: calc(100% - 83px);
        background: radial-gradient(
            ellipse at center,
            #3b4c70 0%,
            #102248 100%
        );
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;

        .top-rect {
            display: none;
            position: absolute;
            right: 135px;
            top: -10px;
            width: 0;
            height: 0;
            border-left: 15px solid transparent;
            border-right: 15px solid transparent;
            border-bottom: 10px solid #102248;
        }

        .top-bar {
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid #FFFFFF1A;

            .left-text {
                margin-left: 28px;
                display: flex;
                flex-direction: column;
                align-items: flex-start;

                .secondary-label {
                    margin-top: 3px;
                }
            }

            .right-info {
                display: flex;
                align-items: center;

                .battle-info {
                    display: flex;
                    align-items: center;
                    img {
                        width: 65px;
                        height: 69px;
                    }

                    .battle-data {
                        margin-left: 10px;
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                    }

                    .standard-fill-bar {
                        width: 120px;
                    }
                }

                .close-wrapper {
                    height: 40px;
                    width: 74px;
                    border-left: 1px solid #FFFFFF1A;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-left: 22px;
                }
            }
        }

        .battle-items {
            display: flex;
            align-items: center;
            justify-content: space-around;
            margin-top: 60px;
        }
    }
}
