.last_hand {
    position: absolute;
    top: 25px;
    left: 36px;
    width: 98px;
    height: 107px;
    border-radius: 5px;
    .last_hand_text {
        height: 25px;
        background: var(--mainColor3);
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid var(--mainColor3);
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;
        span {
            text-transform: uppercase;

            font-weight: 700;
            font-size: 10px;
            color: var(--cColor);
        }
    }
    .last_hand_card_block {
        border: 1px solid var(--mainColor4);
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        height: 82px;
        background: var(--mainColor4);
        position: relative;
        .last_cards_3 {
            position: absolute;
            left: 36px;
            top: 5px;
        }
        .last_cards_2 {
            position: absolute;
            left: 5px;
            top: 20px;
        }
        .last_cards_1 {
            position: absolute;
            left: 36px;
            bottom: 5px;
        }
        .last_cards_4 {
            position: absolute;
            right: 5px;
            top: 20px;
        }
        .last_card_winner{
            border: 1.5px solid var(--mainColor27);
        }
    }
}
