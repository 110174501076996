.waiting-players {
    width: 615px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    position: relative;
    z-index: 5;

    .waiting-popup {
        background: var(--mainColor15);
        width: 100%;
        border: 1px solid #0b2c19;
        margin-top: 110px;
        border-radius: 10px;
        .ribbon-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: -44px;
        }

        .waiting-text-block {
            font-size: 18px;
            color: var(--mainColor2);
            font-family: 'Roboto';
            margin: 0 auto;
            text-align: center;
            .waiting-text {
                padding-top: 40px;
                display: block;
            }
        }
        .waiting-loading-block {
            padding-top: 20px;
            display: flex;
            align-items: center;
            margin-left: 140px;
            height: 52px;
            justify-content: center;
            transition: width 0.5s ease-in-out;
            &.block {
                display: flex;
                width: 190px;
                margin-left: 195px;
                transition: margin-left 5s ease-in-out;
            }
            .waiting-loading {
                width: 50px;
                height: 50px;
                // border-radius: 50%;
                // border: 1px solid var(--mainColor2);
                position: relative;
                box-sizing: border-box;
            }
            .waiting-load {
                overflow: hidden;
                margin-right: 110px;
                flex-direction: column;
                justify-content: center;
                margin-left: 10px;
                transition: margin-right 3s ease-in-out;
            }
        }
        .waiting-table {
            margin: 0 auto;
            text-align: center;
            padding-top: 25px;
            font-size: 15px;
            color: var(--mainColor2);
            font-family: 'Roboto';
            text-transform: uppercase;
        }
        .waiting-chip-block {
            background: #0b2c19;
            border-radius: 10px;
            margin: 16px;
            display: flex;
            justify-content: center;
            align-items: center;
            .waiting-under-block {
                width: 140px;
                display: flex;
                justify-content: space-between;
                .waiting-chip-table {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    margin-left: 10px;
                    .chip-count {
                        font-size: 26px;
                        font-family: 'Roboto';
                        font-weight: 700;
                        color: var(--mainColor2);
                    }
                    .chip-type {
                        font-size: 14px;
                        font-family: 'Roboto';
                        color: var(--mainColor2);
                        text-transform: uppercase;
                    }
                }
            }
        }
    }
}
.me-user {
    width: 120px;
    height: 124px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    .me-user-block {
        background: var(--mainColor6);
        width: 90px;
        height: 90px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        .me-user-img {
            width: 76px;
            height: 76px;
            border-radius: 50%;
            margin: 0 auto;
            img {
                width: 100%;
                height: 100%;
                border-radius: 50%;
                position: relative;
                z-index: 2;
            }
        }
    }
}
.me-user {
    left: 160px;
    bottom: 48px;
}

@keyframes rotation {
    from {
        -webkit-transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
    }
}
.with-cancel {
    animation-name: buttonAnimate;
    animation-delay: 3s;
    animation-duration: 0.4s;
    animation-fill-mode: both;
    position: relative;
    width: 110px;
    height: 52px;
    .button-tree {
        height: 46px;
        position: absolute;
        top: 0;
        z-index: 2;

        button {
            background: radial-gradient(#f1b748 0%, #cb9236 100%);
            margin-right: 10px;
            width: 110px;
            span {
                font-size: 15px;
            }
        }
        .button-tree-under {
            background: #785620;
            position: absolute;
            top: 0;
            width: 110px;
            height: 52px;
            animation-name: buttonAnimate;
            animation-delay: 3s;
            animation-duration: 0.4s;
            animation-fill-mode: both;
        }
    }
}
@keyframes textAnimate {
    from {
        font-size: 0px;
    }
    to {
        font-size: 15px;
    }
}
@keyframes buttonAnimate {
    from {
        width: 0px;
    }
    to {
        width: 110px;
    }
}
// .chit {
//     position: absolute;
//     bottom: 60px;
//     left: 155px;
//     z-index: 10;
//     animation: smiles 1s ease-in-out;
// }
// @keyframes smiles {
//     0% {
//         transform: scale(0.2);
//     }
//     60% {
//         transform: scale(1.2);
//     }
//     100% {
//         transform: scale(1);
//     }
// }
