.lobby_cashe_game {
    display: flex;
    justify-content: space-around;
    margin-top: 90px;
    .lobby_cash_game_block {
        width: 240px;
        .cash_game {
            cursor: pointer;
            transform: scale(0.9);
            transition: 0.4s ease-in-out;
            width: 100%;
            &:hover {
                transform: scale(1);
            }
        }
        .lobby_name {
            .lobby_text {
                top: -35px;
            }
        }
        .lobby_bet {
            position: relative;
            top: -20px;
        }
    }
}
