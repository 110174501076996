.badges-img-block {
    margin-bottom: 10px;
    margin-top: 5px;
    animation: fadeOut 1.5s;
    img {
        width: 120px;
        margin: 0 15px;
    }
    .badge-img-animate {
        position: absolute;
        left: 0;
        animation: scaleImgBlock 1.6s ease-in-out;
    }
}

@keyframes scaleImgBlock {
    0% {
        transform: scale(1);
    }
    30% {
        transform: scale(1.4);
    }
    60% {
        transform: scale(1.4);
    }
    100% {
        transform: scale(1);
    }
}
@keyframes fadeOut {
    0% {
        opacity: 1;
    }
    80% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
