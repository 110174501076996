.gradient-line {
    //height: 58px;
    width: 90%;
    background: linear-gradient(
        to right,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.2) 100%
    );
    margin-top: 2px;
    border-bottom: 1px solid #3b4c70;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    padding: 10px 0;

    .gradient-line-left-side {
        height: 100%;
        display: flex;
        align-items: center;

        img {
            width: 36px;
            height: 38px;
        }

        p {
            width: 250px;
            font-family: Mardoto;
            font-size: 16px;
            font-weight: 200;
            color: var(--cColor);
            margin: 0 0 0 30px;
        }
    }

    .gradient-line-right-side {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-right: 30px;

        .right-side-info {
            display: flex;
            flex-direction: column;
            padding: 0 20px;
            border-left: 1px solid rgba(255, 255, 255, 0.2);

            &:first-child {
                border-left: none;
                padding-left: 0;
            }

            &:last-child {
                padding-right: 0;
            }

            .right-side-info-top {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                text-align: right;

                img {
                    height: 14px;
                    width: 14px;
                    margin-right: 4px;
                }
            }

            .right-side-info-bottom {
                text-align: right;
            }
        }
    }
}
