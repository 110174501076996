.lobby_content {
    overflow: hidden;
    height: 620px;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    margin: auto;
    .lobby_item {
        display: flex;
        flex-direction: column;
        width: 100%;
        position: relative;
        height: 100%;
        .lobby_menu {
            position: relative;
            width: 1000px;
            .lobby_games {
                display: flex;
                justify-content: space-around;
                align-items: center;
                height: 580px;
                .lobby_game {
                    width: 400px;
                    cursor: pointer;
                    .cash_game {
                        width: 100%;
                    }
                    .yellow_rectangle {
                        margin-bottom: 10px;
                    }
                    span {
                        text-transform: uppercase;
                        font-size: 19px;
                        font-weight: 700;
                        color: var(--mainColor2);
                    }
                    .lobby-teaminfo {
                        position: absolute;
                        top: 360px;
                        left: 50%;
                        transform: translateX(-50%);
                    }
                    .lobby_name {
                        position: relative;
                        top: -50px;
                        .lobby_text {
                            top: -20px;
                        }
                    }
                }
            }
        }
        .cash_game_menu {
            width: 1000px;
            position: absolute;
            top: 620px;
            .back_lobby {
                display: flex;
                align-items: center;
                position: relative;
                border-radius: 4px;
                z-index: 3;
                top: 100px;
                left: 0px;
                width: 106px;
                height: 44px;
                background: linear-gradient(to right, #6a0000 0%, #ab0023 100%);
                cursor: pointer;

                span {
                    text-transform: uppercase;
                    color: var(--mainColor2);
                    font-size: 15px;
                    font-weight: 500;
                }
            }
            .cash_games {
                .cash_game_menu_block {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 620px;
                    position: absolute;
                    width: 100%;
                    top: 0;
                }
            }
        }
        .team-game-notification {
            width: 80px;
            height: 80px;
            border-radius: 50%;
            border: 1px solid #c86d70;
            background: linear-gradient(270deg, #8c1d25 0%, #a11f25 100%);
            position: absolute;
            right: -10px;
            bottom: -10px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            transition: width 0.1s linear, height 0.1s linear, left 0.1s linear,
                right 0.1s linear, bottom 0.1s linear;
            span {
                font-size: 21px;
                font-family: 'Mardoto';
                color: #fff;
                text-transform: uppercase;
                font-weight: bold;
                line-height: normal;
            }
        }
    }
}

.move_to_top {
    margin-top: -620px;
    transition: all 0.4s ease-in-out;
}
.move_to_bottom {
    margin-top: 0px;
    transition: all 0.4s ease-in-out;
}
.to_animate {
    top: 160px;
}
