.tour-rewards-line {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgba(52, 44, 34, 0.4);
    margin-bottom: 15px;

    &:last-child {
        border-bottom: 1px solid transparent;
    }
    .rewards-left-block {
        display: flex;
        margin-left: 40px;
        position: relative;
        .item-img {
            position: relative;
            left: -15px;
        }
        .rewards-img {
            font-family: 'DejaVu-Sans-bold';
            color: var(--mainColor2);
            font-size: 20px;
            font-weight: bold;
        }
        .last-reward-user {
            span {
                font-size: 20px;
                color: var(--mainColor2);
                font-weight: 700;
                padding-left: 20px;
            }
        }
        .user-navigator {
            position: absolute;
            left: -40px;
            bottom: 5px;
            width: 30px;
        }
    }
    .rewards-right-block {
        display: flex;
        margin-right: 20px;
        margin-bottom: 10px;
        .reward-vip-block {
            width: 115px;
        }
        .reward-red-block {
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .red-first {
                position: relative;
                margin-left: -45px;
                .red-first-img-first {
                    position: relative;
                    top: -25px;
                    left: 135px;
                }
                .red-first-img-second {
                    position: relative;
                    top: -25px;
                    left: 88px;
                }
                .red-first-img-third {
                    position: relative;
                    top: -25px;
                    left: 42px;
                }
                .red-first-img-four {
                    position: relative;
                    top: -25px;
                    left: -6px;
                }
            }
            .red-second {
                justify-content: center;
                align-items: center;
                .red-second-img-first {
                    position: relative;
                    top: -27px;
                    left: 50px;
                }
                .red-second-img-second {
                    position: relative;
                    top: -27px;
                    left: 24px;
                }
                .red-second-img-third {
                    position: relative;
                    top: -27px;
                    left: -2px;
                }
            }
            .red-third {
                position: relative;
                margin-left: 22px;
                left: -10px;
                .red-third-img-first {
                    position: relative;
                    top: -27px;
                    left: 48px;
                }
                .red-third-img-second {
                    position: relative;
                    top: -27px;
                }
            }
        }

        span {
            font-size: 20px;
            color: var(--mainColor2);
            font-weight: 700;
            padding-right: 5px;
        }
        img {
            width: 28px;
            margin-right: 10px;
        }
    }
}
