.tour-user-panel {
    height: 56px;
    background: linear-gradient(to right, #3b2d1b 0%, #b59a74 100%);
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    position: absolute;
    top: 0;
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.16);
    width: 100%;
    z-index: 1;
    display: flex;
    align-items: center;

    .tour-img-panel {
        position: relative;
        top: 17px;

        cursor: pointer;
    }
    .tour-user-panel-name {
        font-family: 'Roboto-slab';
        font-size: 40px;
        color: var(--mainColor2);
        font-weight: 700;
        text-transform: capitalize;
        padding-left: 20px;
        letter-spacing: 1px;
    }
    .tour-panel-timer {
        background: rgba(56, 41, 25, 0.74);
        display: flex;
        width: 325px;
        height: 40px;
        border-radius: 20px;
        align-items: center;
        position: absolute;
        right: 100px;

        .tour-user-panel-timer {
            width: 100%;
            padding-left: 30px;
            .timer {
                display: flex;

                p {
                    padding-left: 0px;
                    padding-right: 10px;
                }
                span {
                    font-size: 34px;
                    font-family: 'Roboto-slab';
                    padding-left: 0px;
                    padding-right: 10px;
                    color: var(--mainColor2);
                    font-weight: 700;
                }
            }
        }
        img {
            margin-left: 3px;
        }
        p {
            font-family: 'Roboto-slab';
            font-size: 34px;
            color: var(--mainColor2);
            font-weight: 700;
            padding-left: 40px;
        }
    }
    .tour-home-icon {
        position: absolute;
        right: 60px;
        border-left: 1px solid rgba(255, 255, 255, 0.24);
        height: 40px;
        margin-left: 70px;
        cursor: pointer;
        img {
            width: 30px;
            height: 30px;
            position: absolute;
            top: 0;
            left: 15px;
            right: 0;
            bottom: 0;
            margin: auto;
        }
    }
}
