.badge-item {
    position: absolute;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    margin-left: 20px;
    width: 120px;

    transition: all 0.4s ease-in-out;
    top: -3px;
    .badge-img {
        width: 120px;
        transition: all 0.4s ease-in-out;
    }
    .badge-img-animating {
        position: absolute;
        width: 120px;
        height: 113px;

        top: 20px;
        left: -3px;

        animation: animateBadge 2s ease-in-out;
    }
}
@keyframes animateBadge {
    0% {
        transform: scale(1);
        width: 120px;
        height: 113px;

        top: 20px;
        left: 0px;
    }
    50% {
        transform: scale(1.4);
        width: 120px;
        height: 113px;

        top: 20px;
        left: -3px;
    }
    100% {
        transform: scale(1);
        width: 120px;
        height: 113px;

        top: 20px;
        left: -3px;
    }
}
