.choose-team-name {
    .left-side {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .right-side {
        display: flex;
        align-items: center;

        .team-name-content {
            padding: 0 30px 50px 30px;
            text-align: left;

            p {
                font-size: 16px;
                font-weight: 200;
                color: var(--cColor);
                padding-bottom: 20px;
            }
        }

        .buttons-wrapper {
            position: absolute;
            right: 30px;
            bottom: 30px;
            display: flex;
            height: 51px;

            ._3d-button {
                margin-right: 10px;

                &:first-child {
                    padding: 0;
                }

                &:last-child {
                    margin-right: 0;
                }
            }
        }

        .error-banner {
            margin-top: 6px;
            height: 34px;
            .error-text {
                font-size: 12px;
            }
        }
    }
}
