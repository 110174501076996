.badge-bottom-section {
    background: var(--mainColor20);
    height: 28px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    margin-bottom: 3px;
    display: flex;
    justify-content: space-between;
    margin: 0 4px;
    .play-count {
        display: flex;
        align-items: center;
        margin-left: 20px;
        p {
            font-size: 13px;
            color: var(--mainColor21);
            font-weight: 700;
            margin-left: 10px;
        }
    }
    .complete-badge {
        display: flex;
        align-items: center;
        margin-left: 20px;
        p {
            font-size: 13px;
            color: var(--mainColor21);
            font-weight: 700;
            margin-left: 10px;
        }
    }
    .badge-bottom-chip {
        display: flex;
        align-items: center;
        .badge-bottom-chip-count {
            display: flex;
            align-items: center;
            margin-right: 15px;
            span {
                color: var(--mainColor2);
                font-size: 13px;
                font-weight: 700;
                margin-right: 5px;
            }
        }
    }
}

.bottom-animate {
    // animation: scaleBottom 1.6s ease-in-out;
    animation-fill-mode: both;
}

@keyframes scaleBottom {
    0% {
        transform: scale(0);
    }

    75% {
        transform: scale(1);
    }

    90% {
        transform: scale((0.98));
    }

    100% {
        transform: scale(1);
    }
}
