.congrats {
    .left-side {
        display: flex;
        flex-direction: column;
        align-items: center;

        .standard-label {
            margin-top: 60px;
        }

        p {
            padding: 0 40px;
            font-family: 'Mardoto';
            font-size: 16px;
            font-weight: 200;
            color: var(--cColor);
        }

        .universal-shield {
            margin-top: 80px;
        }
    }

    .right-side {
        text-align: left;

        .rules-tips-text {
            margin: 30px 0 0 30px;
            border-bottom: 1px solid #ffffff1a;
            .top-tip {
                width: 354px;
                font-family: 'Mardoto';
                font-size: 16px;
                font-weight: 200;
                color: var(--cColor);
            }
        }

        .tips-list {
            margin-left: 30px;
            height: 384px;
            overflow-y: hidden;

            &.with-scroll {
                overflow-y: scroll;
            }

            .tip-item {
                width: 100%;
                display: flex;
                align-items: center;
                padding: 20px 0;
                box-sizing: border-box;
                border-bottom: 1px solid #ffffff1a;
                opacity: 0.5;

                transition: opacity 0.2s ease-in-out;

                &.active {
                    opacity: 1;
                }

                img {
                    width: 65px;
                    height: 69px;
                }

                p {
                    font-family: 'Mardoto';
                    font-size: 16px;
                    font-weight: 200;
                    color: var(--cColor);
                    max-width: 375px;
                    margin: 0 0 0 10px;
                }
            }

            &.with-scroll {
                .tip-item {
                    opacity: 1;
                }
            }
        }

        .rules-counter {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            height: 110px;
            padding-right: 30px;

            .counter-text {
                text-align: right;
                display: flex;
                flex-direction: column;
                margin-right: 20px;

                .standard-label {
                    text-transform: lowercase;
                }
            }

            .hint-button-wrapper {
                &.no-padding {
                    ._3d-button {
                        padding: unset;
                    }
                }
            }
        }
    }
}
