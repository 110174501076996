.user-info {
    .right-side {
        position: absolute;
        top: 0;
        left: -360px;
        width: 360px;
        height: 100%;
        box-shadow: 7px 0 14px #000000d4;
        background: linear-gradient(to right, #3b4c70 0%, #102248 100%);
        z-index: 1;
        transition: left 0.3s ease-in-out;

        .not-in-team {
            text-align: center;
            align-items: center;
            justify-content: center;
            display: flex;
            flex-direction: column;

            .empty-shield-wrapper {
                width: 100%;
                height: 350px;
                display: flex;
                align-items: center;
                justify-content: center;
                img {
                    margin-top: 50px;
                    width: 150px;
                }
            }

            p {
                margin: 10px auto;
                width: 250px;

                font-size: 16px;
                font-weight: 200;
                color: var(--mainColor2);
            }

            .button-wrapper {
                margin-top: 30px;
            }
        }

        .in-team {
            .member-info {
                display: flex;
                justify-content: center;
                align-items: flex-start;
                flex-direction: column;
                padding: 10px 0 0 20px;
                position: absolute;
                top: 0;

                .team-text {
                    font: Condensed Bold 12px/14px Mardoto;
                    color: #ffffff80;
                }

                .member-text {
                    font: Condensed Bold 15px/18px Mardoto;
                    color: var(--mainColor2);
                }
            }

            .in-team-shield {
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 75px;
                .universal-shield {
                    transform: scale(0.9);
                }
            }

            .right-content {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                width: 100%;
                height: 235px;
                .gradient-line {
                    border-bottom: 1px solid transparent;
                }
                span {
                    color: var(--mainColor2);
                    text-transform: capitalize;
                }
            }

            .gradient-lines {
                display: flex;
                align-items: flex-end;
                flex-direction: column;
                text-align: right;
                margin-top: 40px;
                width: 100%;
                .gradient-line-first {
                    position: relative;
                    display: flex;
                    width: 325px;
                    justify-content: center;
                    margin-bottom: 15px;
                    .gradient-line {
                        width: 100%;
                        .gradient-line-left-side {
                            position: absolute;
                            left: -10px;
                            img {
                                width: 58px;
                                height: 64px;
                            }
                        }
                        .gradient-line-right-side {
                            padding-left: 30px;
                        }
                    }
                }
                &:first-child {
                    margin-top: 0;
                }
            }

            ._3d-button {
                margin-top: 30px;
            }
            .visit-button {
                position: relative;
                top: -8px;
                left: calc(50% - 70px);
                height: 46px;
                button {
                    background: linear-gradient(
                        to right,
                        #4f5e18 0%,
                        #36442c 100%
                    );
                }
                .button-tree-under {
                    bottom: -6px;
                }
                span {
                    font-size: 15px;
                }
            }
        }

        .right-info-text-wrapper {
            flex-direction: column;
            display: flex;
            align-items: center;
            margin-top: 20px;
            justify-content: center;
        }
    }
}
