.divided-view {
    width: 100%;
    height: 100%;
    .divided-view-content {
        width: 100%;
        height: 100%;
        display: flex;

        .left-side-wrapper {
            width: 40%;
            height: 100%;
            box-shadow: 7px 0 14px #000000d4;
            background: linear-gradient(270deg, #3b4c70 0%, #102248 100%);
            z-index: 2;
            position: relative;
            overflow: hidden;

            .left-side {
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;

                transition: top 0.3s ease-in-out;

                &.hide-top {
                    top: -100%;
                }

                &.hide-bottom {
                    top: 100%;
                }
            }
        }

        .right-side-wrapper {
            width: 60%;
            height: 100%;
            background: rgba(0, 0, 0, 0.5);
            background-size: 100%;
            position: relative;
            overflow: hidden;

            &.with-shield {
                background: rgba(0, 0, 0, 0.5)
                    url('../../assets/images/team-game/backgrounds//shield-frame.svg')
                    left no-repeat;
            }

            .right-side {
                width: 100%;
                height: 100%;
                top: 0;
                right: 0;
                position: absolute;

                transition: right 0.3s ease-in-out;

                &.hide-right {
                    right: -100%;
                }

                &.hide-left {
                    right: 100%;
                }
            }
        }
    }
}
