.choose-team-logo {
    .left-side {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        .text-wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 0 20px;

            .hint-text {
                font-size: 16px;
                font-weight: 200;
                color: var(--cColor);
                margin-top: 10px;
            }
        }

        .highlight-buttons {
            display: flex;
            margin-top: 20px;
            min-height: 51px;

            ._3d-button {
                padding: unset;
                min-width: 87px;
                margin-left: 16px;
                &:first-child {
                    margin-left: 0;
                }
            }
        }

        .choose-team-shield-wrapper {
            position: relative;
            width: 100%;
            padding: 34px 20px;
            box-sizing: border-box;

            .random-image {
                position: absolute;
                right: 20px;
                top: -20px;
                width: 72px;
                height: 72px;
                cursor: pointer;

                transition: top 0.1s ease-in-out, right 0.1s ease-in-out,
                    width 0.1s ease-in-out, height 0.1s ease-in-out;

                &:hover {
                    width: 76px;
                    height: 76px;
                    top: -22px;
                    right: 18px;
                }

                &:active {
                    width: 68px;
                    height: 68px;
                    top: -18px;
                    right: 22px;
                }
            }

            .universal-shield {
                margin: 0 auto;
                transform: scale(1.2);
                margin-top: 20px;
            }
        }
    }

    .right-side {
        display: flex;
        flex-direction: column;

        .floating-tabs-wrapper {
            padding: 0 42px;
        }

        .icons-content {
            height: calc(100% - 181px);
            border-top: 1px solid #ffffff1a;
            border-bottom: 1px solid #ffffff1a;
            overflow-y: auto;

            padding: 30px 27px;

            display: flex;
            flex-direction: column;

            .icon-section {
                display: flex;
                flex-direction: column;
                margin-top: 30px;

                &:first-child {
                    margin-top: 0;
                }

                .icon-namings {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .left-namings {
                        display: flex;
                        flex-direction: column;
                        text-align: left;
                        padding-left: 16px;

                        .premium {
                            color: #e8ba73;
                            text-transform: uppercase;
                            font-family: 'Mardoto';
                            font-weight: bold;
                        }
                    }

                    .buy-icons {
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        img {
                            width: 50px;
                            height: 50px;
                            margin-right: 8px;
                        }

                        .price-wrapper {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                        }

                        ._3d-button {
                            margin-left: 15px;
                            padding: 0 10px;
                        }

                        &.shaking {
                            ._3d-button {
                                animation: shake 0.82s
                                    cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
                            }
                        }
                    }
                }

                .icon-listing {
                    display: flex;
                    flex-wrap: wrap;
                    margin-top: 4px;

                    .listing-line {
                        display: flex;
                        align-items: center;
                        width: 100%;

                        .icon-wrapper {
                            width: 76px;
                            height: 76px;
                            border-radius: 10px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            cursor: pointer;
                            margin-left: 17px;

                            &.disabled {
                                opacity: 0.5;
                            }

                            &:first-child {
                                margin-left: 0;
                            }

                            &:not(.disabled):hover {
                                background: #ffffff1a;
                            }

                            img {
                                width: 44px;
                                height: 44px;
                            }
                        }
                    }

                    &.not-purchased {
                        .icon-wrapper {
                            cursor: unset;
                            &:not(.disabled):hover {
                                background: unset;
                            }
                            img {
                                opacity: 0.5;
                            }
                        }
                    }
                }
            }
        }

        .buttons-wrapper {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            padding: 0 30px;
            height: 101px;

            ._3d-button {
                box-sizing: border-box;
                margin-right: 10px;

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }
}

@keyframes shake {
    10%,
    90% {
        transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
        transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
        transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
        transform: translate3d(4px, 0, 0);
    }
}
