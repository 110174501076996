.error-banner {
    width: 100%;
    height: 49px;
    border-radius: 10px;
    border: 1px solid #ffffff42;
    background: linear-gradient(to top, var(--mColor) 0%, var(--lColor) 100%);
    display: flex;
    align-items: center;
    justify-content: center;

    .error-text {
        font: Condensed Bold 19px Mardoto;
        color: var(--cColor);
    }
}
