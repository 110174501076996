.thin-bar {
    padding: 0 4px;
    height: 14px;
    border: 0.5px solid var(--fColor);
    background: var(--dColor);
    width: 100%;
    border-radius: 18px;
    position: relative;
    box-sizing: border-box;

    .inner-fill {
        position: absolute;
        left: 3px;
        top: 3px;
        height: 6px;
        border-radius: 20px;
        box-shadow: inset 0 0 2px var(--lColor);
    }
}
