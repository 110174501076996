.user-chat {
    position: absolute;
    top: 40px;
    z-index: 10;
    background-image: url('../../../assets/images/game-info/chat_tip.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    // box-shadow: 15px 15px 13px rgba(0, 0, 0, 16);
    width: 120px;
    height: 43px;

    span {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-size: 14px;
        font-weight: 700;
        height: 32px;
    }
}

.user-chat-3 {
    top: 40px;
}
.user-chat-blur {
    position: absolute;
    top: 42px;
    left: -10px;
    background-image: url('../../../assets/images/game-info/chat_tip_blur.png');
    background-repeat: no-repeat;
    z-index: 9;
    background-size: 100% 100%;
    width: 150px;
    height: 60px;
}
