.joining-conditions-content {
    .left-side {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        .edit-icon {
            position: absolute;
            right: 20px;
            top: 20px;
        }
    }

    .right-side {
        text-align: left;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 0 60px 0 30px;
        box-sizing: border-box;
        p {
            font-size: 16px;
            font-weight: 200;
            color: var(--cColor);
        }

        .set-conditions {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 30px;

            .condition-wrapper {
                min-width: 196px;
                margin-left: 50px;

                &:first-child {
                    margin-left: 0;
                }

                .error-empty-space {
                    height: 34px;
                    width: 100%;
                    margin-top: 5px;
                }

                .standard-textbox {
                    margin-top: 15px;
                    text-align: left;
                    padding-left: 20px;
                    box-sizing: border-box;
                }

                .error-banner {
                    margin-top: 5px;
                    height: 34px;
                    padding-left: 10px;
                    box-sizing: border-box;
                    span {
                        font-size: 12px;
                    }
                }
            }
        }

        .buttons-wrapper {
            position: absolute;
            right: 30px;
            bottom: 30px;
            display: flex;
            height: 51px;

            ._3d-button {
                margin-right: 10px;
                &:first-child {
                    padding: unset;
                }
                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }
}
