.fill-your-team {
    box-shadow: 0 3px 6px #00000029;
    border: 1px solid #ffffff1a;
    border-radius: 10px;
    background: #14264c;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 25px;
    margin-top: 12px;
    .standard-label {
        padding-bottom: 10px;
    }
    &.without-back {
        background: unset;
        border: unset;
        box-shadow: unset;
    }

    p {
        width: 312px;
        font-family: 'Mardoto';
        font-size: 16px;
        font-weight: 200;
        color: var(--cColor);
        text-align: center;
        padding-bottom: 15px;
    }
    .button-wrapper {
        height: 48px;
    }
    ._3d-button {
        padding: 0;
    }
}
