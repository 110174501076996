.hex-flipper {
    cursor: pointer;
    width: 71px;
    height: 75px;
    position: relative;

    img {
        position: absolute;
        width: 32px;
        height: 35px;
        z-index: 1;
        transition: width 0.3s linear, height 0.3s linear, left 0.3s linear,
            top 0.3s linear;
        left: 39px;
        top: 40px;

        &.main {
            left: 0;
            top: 0;
            width: 71px;
            height: 75px;
        }
    }
}
