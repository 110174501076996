* {
    padding: 0;
    margin: 0;
    border: 0;
    outline: 0;
    border-radius: 0;
    background: none;
    vertical-align: top;
    -webkit-font-smoothing: subpixel-antialiased;
    -webkit-tap-highlight-color: transparent;
    user-select: none;
    ::-moz-focus-inner {
        border: 0;
    }
    &,
    &:after,
    &:before {
        box-sizing: border-box;
    }
}
@font-face {
    font-family: 'icomoon';
    src: url('./assets/fonts/icomoon.eot?bcqrz7');
    src: url('./assets/fonts/icomoon.eot?bcqrz7#iefix')
            format('embedded-opentype'),
        url('./assets/fonts/icomoon.ttf?bcqrz7') format('truetype'),
        url('./assets/fonts/icomoon.woff?bcqrz7') format('woff'),
        url('./assets/fonts/icomoon.svg?bcqrz7#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-A:before {
    content: '\e900';
}
.icon-Clubs-icon:before {
    content: '\e901';
}
.icon-Computer-icon:before {
    content: '\e902';
}
.icon-Dimonds-icon:before {
    content: '\e903';
    color: #d72128;
}
.icon-Hearts-icon:before {
    content: '\e904';
    color: #d72128;
}
.icon-Minus:before {
    content: '\e905';
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}
.icon-Plus:before {
    content: '\e906';
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}
.icon-Spade-icon:before {
    content: '\e907';
}

@font-face {
    font-family: 'DejaVu Sans';
    src: url('./assets/fonts/DejaVuSans.ttf');
}
@font-face {
    font-family: 'Mardoto';
    src: url('./assets/fonts/Mardoto-Thin.ttf');
    font-weight: 200;
}
@font-face {
    font-family: 'Mardoto';
    src: url('./assets/fonts/Mardoto-Bold.ttf');
    font-weight: 700;
}
@font-face {
    font-family: 'Mardoto';
    src: url('./assets/fonts/Mardoto-Regular.ttf');
    font-weight: 500;
}
@font-face {
    font-family: 'MardotoBlack';
    src: url('./assets/fonts/Mardoto-Black.ttf');
    font-weight: 500;
}
@font-face {
    font-family: 'Roboto';
    src: url('./assets/fonts/Roboto-Thin.ttf');
    font-weight: 200;
}
@font-face {
    font-family: 'Roboto';
    src: url('./assets/fonts/Roboto-Bold.ttf');
    font-weight: 700;
}
@font-face {
    font-family: 'Roboto';
    src: url('./assets/fonts/Roboto-Regular.ttf');
    font-weight: 500;
}

@font-face {
    font-family: 'DejaVu-Sans-bold';
    src: url('./assets/fonts/DejaVuSans-Bold.ttf');
    font-weight: bold;
}

@font-face {
    font-family: 'Noto Sans';
    src: url('./assets/fonts/NotoSansRegular.ttf');
}
@font-face {
    font-family: 'NotoSans-Bold';
    src: url('./assets/fonts/NotoSans-Bold.ttf');
}

@font-face {
    font-family: 'MyRad Pro';
    src: url('./assets/fonts/MyradPro-Bold.woff');
}
@font-face {
    font-family: 'Dejavu-book';
    src: url('./assets/fonts/DejaVu-Sans-Book.ttf');
}

@font-face {
    font-family: 'Roboto-Slab';
    src: url('./assets/fonts/RobotoSlab-Black_BlotClub_Edition.ttf');
}

:root {
    --mainColor1: rgb(238, 201, 158);
    --mainColor2: #fff;
    --mainColor3: #51ac64;
    --mainColor4: #79c68d;
    --mainColor5: #793d1d;
    --mainColor6: #114223;
    --mainColor7: #201112;
    --mainColor8: rgba(32, 43, 28, 0.9);
    --mainColor9: #ffecb7;
    --mainColor10: #4f2222;
    --mainColor11: #341d1c;
    --mainColor12: #98543e;
    --mainColor13: rgb(238, 201, 158, 0.2);
    --mainColor14: rgba(255, 255, 255, 0.2);
    --mainColor15: #113f23;
    --mainColor16: rgba(11, 44, 25, 1);
    --mainColor17: #430000;
    --mainColor18: #f6c58c;
    --mainColor19: #fdc27f;
    --mainColor20: #4a1e1e;
    --mainColor21: #a0846c;
    --mainColor22: #187d32;
    --mainColor23: #2a903a;
    --mainColor24: rgba(255, 255, 255, 0.58);
    --mainColor25: #e6bc95;
    --mainColor26: #000;
    --mainColor27: #ffc400;
    --mainColor30: #6a0000;
    --mainColor31: #ab0023;
    --mainColor32: #97001f;
    --aColor: #201017;
    --bColor: #2a0f0c;
    --cColor: #ffffff;
    --eColor: #5e1f15;
    --fColor: #f2ba39;
    --gColor: #c41217;
    --hColor: #ecdec1;
    --kColor: #fcb912;
    --lcolor: #000000;
}
.tournament-temp {
    --mainColor1: rgb(238, 201, 158);
    --mainColor2: #fff;

    --mainColor3: #51ac64;
    --mainColor4: #79c68d;
    --mainColor5: #793d1d;
    --mainColor6: #114223;
    --mainColor7: #201112;
    --mainColor8: rgba(32, 43, 28, 0.9);
    --mainColor9: #ffecb7;
    --mainColor10: #4f2222;
    --mainColor11: #341d1c;
    --mainColor12: #98543e;
    --mainColor13: rgb(238, 201, 158, 0.2);
    --mainColor14: rgba(255, 255, 255, 0.2);
    --mainColor15: #113f23;
    --mainColor16: rgba(11, 44, 25, 1);
    --mainColor17: #382919;
    --mainColor18: #f6c58c;
    --mainColor19: #fdc27f;
    --mainColor20: #4a1e1e;
    --mainColor21: #a0846c;
    --mainColor22: #187d32;
    --mainColor23: #2a903a;
    --mainColor24: rgba(255, 255, 255, 0.58);
    --mainColor25: #e6bc95;
    --mainColor26: #000;
    --mainColor27: #ffc400;
    --mainColor28: #430000;
    --mainColor30: #504435;
    --mainColor31: #b59a74;
    --mainColor32: #b59a74;

    --aColor: #201017;
    --bColor: #2a0f0c;
    --cColor: #ffffff;
    --eColor: #5e1f15;
    --fColor: #f2ba39;
    --gColor: #c41217;
    --hColor: #ecdec1;
    --kColor: #fcb912;
    --lcolor: #000000;
}
.team-game-inner {
    --aColor: #3b4c70;
    --bColor: #102248;
    --cColor: #ffffff;
    --dColor: #00000024;
    --eColor: #00000066;
    --fColor: #00000029;
    --gColor: #000000;
    --hColor: #76866c;
    --iColor: #8ea535;
    --jColor: #b46643;
    --kColor: #7593b0;
    --lColor: #ad2125;
    --mColor: #871b24;
    --nColor: #eab0597a;
    --oColor: #eaa844;
    --pColor: #707070;
    --qColor: #3a4253;
    --rColor: rgba(255, 255, 255, 0.58);
    --sColor: #00000073;
    --tColor: #14264c;
    --uColor: #ffffff1a;
    --vColor: #ffffff80;
}
.second-theme {
    --mainColor1: #fff;
    --mainColor2: #333;
}

body {
    margin: 0;
    padding: 0;
    font-family: 'Mardoto', -apple-system, BlinkMacSystemFont, 'Segoe UI',
        'Mardoto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: #fff;
}

ul,
p {
    margin: 0;
    padding: 0;
    font-family: 'Mardoto', 'Roboto';
}

span {
    font-family: 'Mardoto', 'Roboto';
}
div {
    font-family: 'Mardoto', 'Roboto';
}

li {
    list-style: none;
}
