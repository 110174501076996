.chip_info,
.chip_info_discount {
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--mainColor17);
    border-radius: 18px;
    margin-left: 25px;
    height: 32px;
    margin-top: 12px;
    position: relative;
    cursor: pointer;
    .plus-icon {
        position: absolute;
        bottom: -5px;
        left: 18px;
    }
    .chip {
        cursor: pointer;
        margin-left: 3px;
    }
    .chip_discount {
        margin-left: 0px;
        position: relative;
        top: 0px;
        left: 3px;
        width: 28px;
    }
    .amount,
    .amount_discount {
        color: var(--cColor);
        font-weight: 700;
        font-size: 16px;
        padding: 12px;
    }
    .amount_discount {
        padding: 4px;
        position: relative;
        left: 0px;
        padding: 4px;
    }
}
.chip_info_discount {
    z-index: 1;
    position: relative;
    top: -10px;
    width: 125px;
    img {
        position: absolute;
        left: -1px;
    }
}
