.thin-button {
    outline: none;
    font-family: 'Mardoto', 'Roboto';
    font-size: 13px;
    font-weight: bold;
    color: var(--cColor);
    border: none;
    padding: 5px 20px;
    border-radius: 25px;
    text-transform: uppercase;
    cursor: pointer;
    position: relative;

    &.active:after {
        content: '';
        position: absolute;
        top: -6px;
        left: 0;
        right: 0;
        margin: auto;
        width: 0;
        height: 0;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
    }

    &.red {
        background: linear-gradient(to right, #ad2125 0%, #871b24 100%);

        &.active:after {
            border-bottom: 6px solid #ad2125;
        }
    }

    &.dark {
        background: var(--qColor);

        &.active:after {
            border-bottom: 6px solid var(--qColor);
        }
    }

    &.silver {
        background: #c1bcac;

        &.active:after {
            border-bottom: 6px solid #c1bcac;
        }
    }
}
