.game_chip_info {
  display: flex;
  padding: 0 15px;
  .game_chip_info_main {
    display: flex;
    justify-content: center;
    flex-direction: column;
    .game_chip_count {
      font-size: 26px;
      font-weight: 700;
      color: var(--mainColor2);
    }
    .game_chip_text {
      font-size: 14px;
      font-weight: 300;
      color: var(--mainColor2);
    }
  }
}
