.search-textbox-input {
    width: 100%;
    height: 65px;
    border: 1px solid #ffffff1a;

    border-radius: 10px;
    overflow: hidden;

    position: relative;

    input {
        height: 65px;
        width: 100%;
        background: #15233e;
        border: none;
        outline: none;
        padding-left: 20px;
        padding-right: 64px;
        box-sizing: border-box;
        font: Condensed Bold 19px Mardoto;
        color: var(--cColor);

        &::placeholder {
            text-align: center;
            color: rgba(255, 255, 255, 0.5);
            font: Condensed Bold 19px Mardoto;
        }
    }

    .action-icon {
        position: absolute;
        top: 20px;
        right: 20px;
        width: 24px;
        height: 24px;

        &.cursored {
            cursor: pointer;
        }
    }
}
