.chat {
    width: 212px;
    height: 540px;
    position: absolute;
    top: 64px;
    right: 0;
    z-index: 1;
    .chat-user {
        background: linear-gradient(#4b0000 0%, #740017 100%);
        right: -200px;
        width: 212px;
        height: 540px;
        position: absolute;
        transition: right 0.5s ease-in-out;
        border-bottom-left-radius: 16px;
        border-top-left-radius: 16px;
        box-shadow: -6px 0px 20px rgba(0, 0, 0, 0.5);
        .background_popup {
            border-radius: 0px;
            border-bottom-left-radius: 16px;
            border-top-left-radius: 16px;
            height: 540px;
            .background_pattern_popup {
                background-position: -142px -272px;
                background-size: 330% 266%;
                p {
                    color: var(--mainColor2);
                    padding-top: 16px;
                    text-transform: capitalize;
                    font-size: 23px;
                    font-weight: 700;
                    padding-left: 15px;
                    padding-bottom: 15px;
                }
                .menu-gradline {
                    margin-left: 15px;
                    width: 86px;
                    height: 1px;
                    background: linear-gradient(
                        to right,
                        #fff700 0%,
                        rgba(97, 0, 0, 0)
                    );
                }
                .chat-block {
                    display: flex;
                    flex-direction: column;
                    margin: 20px;

                    .chat-data {
                        background-image: url('../../../assets/images/icons/user-chat.svg');
                        background-repeat: no-repeat;
                        background-size: 100% 100%;
                        height: 70px;
                        margin-bottom: 5px;
                        cursor: pointer;
                        span {
                            font-size: 16px;
                            text-align: center;
                            font-weight: 700;
                            display: block;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            height: 60px;
                        }
                    }
                }
            }
        }
    }
    &.open {
        .chat-user {
            right: 0;
        }
    }
    ::-webkit-scrollbar {
        width: 5px;
    }

    /* Track */
    // ::-webkit-scrollbar-track {
    //     background: linear-gradient(#fff 0%, #a2a2a2 100%);
    // }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: linear-gradient(#fff 70%, #a2a2a2 100%);
        border-radius: 5px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: linear-gradient(#fff 0%, #a2a2a2 100%);
    }
}
