.badges-page {
    .badges {
        width: 1000px;
        height: 520px;
        position: relative;
        top: 60px;
        .back_lobby {
            top: 45px;
        }

        .badges-header {
            display: flex;
            justify-content: space-around;
            .badges-header-left {
                display: flex;
                align-items: center;
                margin-left: 80px;
                p {
                    font-size: 28px;
                    font-weight: 700;
                    color: var(--mainColor2);
                    text-transform: uppercase;
                    padding-left: 15px;
                    letter-spacing: 0.84px;
                }
            }
            .badges-header-right {
                display: flex;
                flex-direction: column;
                margin-right: -70px;

                .badges-header-right-block {
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;

                    img {
                        width: 30px;
                    }
                    .badges-header-dashed {
                        border: 1px dashed #fff;
                        width: 40px;
                        height: 1px;
                    }
                }
                .badges-header-text {
                    position: relative;
                    top: 15px;
                    span {
                        display: block;
                        color: var(--mainColor2);
                    }
                }
            }
        }
        .badges-list {
            overflow: scroll;
            height: 490px;
            position: relative;
            margin-top: 30px;
            padding-bottom: 60px;
            .badges-list-block {
                .badges-block {
                    display: flex;
                    justify-content: space-around;
                    height: 180px;
                    border-bottom: 2px solid #80001b;
                    padding-bottom: 15px;
                    width: 96%;
                    margin: 0 auto;
                }
                .badges-border-block {
                    width: 940px;
                    border: 1px solid rgba(97, 0, 0, 1);
                    margin: 10px 25px 10px 25px;
                    border-bottom: 1px solid rgba(255, 145, 145, 0.16);
                    &:last-child {
                        border: 1px solid transparent;
                        margin: 0;
                        border-bottom: 1px solid transparent;
                    }
                }
            }
        }
    }

    .red_chip {
        position: fixed;
        transition: all 2s ease-in-out;
        z-index: 2;
    }
    ::-webkit-scrollbar {
        width: 5px;
    }

    /* Track */
    // ::-webkit-scrollbar-track {
    //     background: linear-gradient(#fff 0%, #a2a2a2 100%);
    // }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: linear-gradient(#fff 70%, #a2a2a2 100%);
        border-radius: 5px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: linear-gradient(#fff 0%, #a2a2a2 100%);
    }
}
