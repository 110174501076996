.tournament-page {
    .tournament-bg {
        .tournament-bg-pattern {
            background-position: -4px 0px;
            background-size: 100% 100%;
            &::before {
                background-position: 0px 65px;
            }
            .tournament {
                .tour-lobby {
                    position: absolute;
                    top: 40px;
                    left: 0;
                    width: 106px;
                    height: 44px;
                    .back_lobby {
                        top: 0;
                    }
                }
                display: flex;
                justify-content: center;
                align-items: center;
                height: 560px;
                position: relative;
                top: 60px;
                .tour-table-game {
                    display: flex;
                    width: 100%;
                    justify-content: space-around;
                    align-items: center;

                    .tour-game {
                        margin-top: 90px;
                        cursor: pointer;
                        position: relative;

                        .lobby_name {
                            margin-top: 50px;
                            .lobby_text {
                                font-family: 'Roboto-Slab';
                                position: absolute;
                                bottom: 20px;
                            }
                        }
                    }
                }
            }
        }
    }
}
