.left-section {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    p {
        font-size: 16px;
        color: var(--mainColor2);
        text-transform: capitalize;
        padding-bottom: 5px;
        font-weight: 500;
    }
    .star-block {
        display: flex;
        flex-direction: row;
        margin-bottom: 10px;
        img {
            width: 14px;
            height: 14px;
            margin: 0 5px;
        }
    }
    span {
        display: block;
        padding-bottom: 10px;
        color: var(--mainColor2);
        margin-bottom: 5px;
        font-weight: 700;
    }
    .level-full {
        left: 0;
    }
}
