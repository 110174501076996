.vip_tab_popup {
    display: flex;
    justify-content: space-around;
    width: 100%;
    position: relative;
    top: -8px;
    .vip_tab_item {
        position: relative;
        width: 184px;
        height: 312px;
        .vip_tab_bg {
            width: 100%;
            height: 100%;
            background-image: url(../../../assets/images/popups/store/store_bg.svg);
            background-repeat: no-repeat;
            position: relative;
            .vip_tab_chip {
                position: absolute;
                left: 0;
                right: 0;
                margin: auto;
                top: -30px;
                z-index: 1;
            }
            .vip_tab_chip_block {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                padding-top: 25px;

                .vip_tab_buy_chip {
                    display: flex;
                    position: relative;
                    top: 170px;
                    width: 100%;
                    justify-content: space-evenly;
                    .vip_tab_buy_chip_block {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-top: -2pxx;
                        p {
                            color: var(--mainColor2);
                            font-size: 16px;
                            padding-left: 8px;
                            padding-top: 2px;
                        }
                        .yellow-bg {
                            width: 27px;
                            height: 27px;
                        }
                        .green-bg {
                            width: 27px;
                            height: 27px;
                        }
                        .red-bg {
                            position: relative;
                            background: rgba(67, 0, 0, 0.4);
                            width: 27px;
                            height: 27px;
                            border-radius: 50%;
                            .red-img {
                                position: absolute;
                                top: 0;
                                left: 0;
                                right: 0;
                                bottom: 0;
                                margin: auto;
                            }
                        }
                    }
                    img {
                        width: 23px;
                        height: 23px;
                    }
                }
                .vip-content {
                    .vip_tab_text {
                        font-size: 25px;
                        color: var(--mainColor2);
                        font-family: 'Roboto-Slab';
                        text-transform: uppercase;
                        letter-spacing: 1.2px;
                        text-align: center;
                        position: relative;
                        padding-top: 5px;
                        z-index: 1;
                    }
                    img {
                        position: absolute;
                        top: 0;
                        left: -30px;
                    }
                    .vip-week {
                        top: 30px;
                    }
                    .vip-month {
                        left: -35px;
                        top: 5px;
                    }
                    .vip-year {
                        top: -15px;
                    }
                }

                .vip_tab_item_buy_now {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 0 14px;
                    width: 174px;
                    height: 42px;
                    border-radius: 6px;
                    background: linear-gradient(#fbdf4c 0%, #f6b72b 100%);
                    position: absolute;
                    bottom: 5px;
                    cursor: pointer;
                    p,
                    span {
                        color: #5e0000;
                        font-size: 17px;
                        font-family: 'MardotoBlack';
                        text-transform: uppercase;
                        z-index: 1;
                    }
                    span {
                        font-size: 19px;
                    }
                }
                .button-layer {
                    position: absolute;
                    height: 19px;
                    width: 174px;
                    background: rgba(255, 122, 122, 0.12);
                    bottom: 5px;
                }
            }
        }
    }
}
