#team-dashboard {
    .content {
        display: flex;
        .left-side {
            width: 500px;
        }
        .right-side {
            width: 500px;
        }
    }
}
