.league-introduction {
    .left-side {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .right-side {
        position: relative;
        padding: 30px 0;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;

        .introduction-info {
            padding-left: 30px;
            display: flex;
            flex-direction: column;
            text-align: left;

            .secondary-label {
                font-weight: normal;
                margin-top: 10px;
            }
        }

        .intro-list {
            padding-left: 30px;
            margin-top: 30px;
            .intro-list-item {
                display: flex;
                align-items: center;
                text-align: left;
                padding: 20px 0;
                border-bottom: 1px solid #ffffff1a;

                img {
                    width: 65px;
                    height: 69px;
                }

                p {
                    width: 320px;
                    font: 200 16px/24px Mardoto;
                    color: var(--cColor);
                    margin-left: 10px;
                }
            }
        }

        ._3d-button {
            position: absolute;
            right: 30px;
            bottom: 30px;
        }

        .access-lock-wrapper {
            width: 100%;
            position: absolute;
            bottom: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        //.lock-button {
        //    position: absolute;
        //    bottom: 30px;
        //    margin-top: 30px;
        //    width: 482px;
        //    margin-left: 30px;
        //}
    }
}
