.first-time-use-popup {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    .close-button {
        position: absolute;
        top: -80px;
        right: 22px;
    }

    .tfu-image {
        width: 680px;
        height: 485px;
        margin-left: 18px;
    }

    .ftu-info {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding-right: 22px;
        margin-bottom: 82px;

        .label-wrapper {
            span {
                display: block;
                width: 280px;
                text-align: center;
            }
        }

        ._3d-button {
            margin-top: 17px;
            padding: 0;
        }
    }
    .first-time-button-wrapper {
        height: 48px;
    }
}
