.notification-item {
    background: #14264c;
    box-shadow: 0 3px 6px #00000029;
    border: 1px solid #ffffff1a;
    border-radius: 10px;
    padding: 20px;
    text-align: left;

    .message {
        display: block;
        width: 280px;
        color: var(--cColor);
        line-height: 24px !important;
    }

    .actions-wrapper {
        display: flex;
        justify-content: flex-end;
        margin-top: 30px;
        height: 51px;
        ._3d-button {
            padding: 0;
            margin-left: 10px;

            &:first-child {
                margin-left: 0;
            }
        }
    }
}
