.spector-chart,
.spector-user {
    position: absolute;
    width: 100px;
    height: 100px;
    bottom: 28px;
    left: 10px;
    transform: scale(1.1);
    #id {
        position: absolute;
    }
    img {
        position: absolute;
        width: 70px;
        height: 70px;
    }
}
.spector-user {
    position: absolute;
    width: 90px;
    bottom: -5px;
    left: -7px;
    transform: scale(1);
}
