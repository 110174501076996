.hexagon-info {
    position: relative;

    .hex-back {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }

    .hex-img {
        position: absolute;
        top: 3px;
        left: 3px;
    }
}