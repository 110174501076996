.second-animate {
    position: absolute;
    top: 0;
    width: 758px;
    height: 150px;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 10px;

    .new-badges-section {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        animation: secondAnimate 2.3s ease-in-out;
        .badge-img-animating {
            width: 120px;
        }
        .new-badges-text-animating {
            margin-left: 30px;

            span {
                font-size: 15px;
                font-weight: 500;
                color: var(--mainColor2);
                text-transform: capitalize;
                padding-bottom: 5px;
            }
            p {
                font-size: 19px;
                font-weight: 700;
                color: var(--mainColor2);
                text-transform: uppercase;
                padding-bottom: 10px;
            }
            .new-badges-block {
                display: flex;
                justify-content: space-between;
                align-items: center;
                .new-badges-chip {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    span {
                        padding-top: 5px;
                        padding-left: 10px;
                        font-size: 21px;
                        font-weight: 700;
                    }
                    &:last-child {
                        margin-left: 10px;
                    }
                }
            }
        }
    }
}
@keyframes secondAnimate {
    0% {
        opacity: 0;
        margin-top: 100px;
    }

    30% {
        opacity: 1;
        margin-top: -10px;
    }
    40% {
        opacity: 1;
        margin-top: 0px;
    }
    80% {
        opacity: 1;
        margin-top: 0px;
    }
    100% {
        opacity: 0;
        margin-top: 100px;
    }
}
