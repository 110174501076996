.standard-textbox {
    width: 100%;
    height: 65px;
    border: 1px solid #ffffffb3;
    border-radius: 10px;
    background: var(--bColor);
    outline: none;
    text-align: center;
    font: Condensed Bold 19px/22px Mardoto;
    color: var(--cColor);

    &.uppercased {
        text-transform: uppercase;
    }

    &.with-error {
        border: 1px solid #881b24;
    }

    &::placeholder {
        color: rgba(255, 255, 255, 0.5);
    }
}
