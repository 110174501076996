.wheel-tab {
    display: flex;
    position: relative;
    width: 184px;
    height: 328px;
    cursor: pointer;
    .wheel-bg {
        width: 100%;
        height: 100%;
        background-image: url(../../../assets/images/popups/store/store_bg.svg);
        background-repeat: no-repeat;
        position: relative;
        .wheel-content {
            position: relative;
            img {
                position: absolute;
                left: -20px;
                right: 0;
                margin: auto;
            }
            .wheel-text {
                background: #530004;
                width: 166px;
                height: 30px;
                border-radius: 15px;
                position: absolute;
                left: 0;
                right: 0;
                top: 120px;
                margin: auto;

                span {
                    font-size: 15px;
                    color: var(--mainColor2);
                    display: block;
                    text-align: center;
                    padding-top: 5px;
                }
            }
        }
        .wheel-under,
        .wheel-no-active {
            position: absolute;
            bottom: 20px;
            left: 0;
            right: 0;
            margin: auto;
            background: linear-gradient(#fbdf4c 0%, #f6b72b 100%);
            border-radius: 6px;
            width: 174px;
            height: 42px;
            span {
                color: #5e0000;
                font-size: 17px;
                font-family: 'MardotoBlack';
                display: block;
                text-align: center;
                text-transform: uppercase;
                padding-top: 10px;
                z-index: 1;
            }
        }
        .wheel-no-active {
            background: rgb(116, 88, 88);
        }
        .button-layer {
            position: absolute;
            height: 19px;
            width: 174px;
            background: rgba(255, 122, 122, 0.12);
            bottom: 20px;
        }
    }
}
