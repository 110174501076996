.chip-navbar-tab {
    display: flex;
    justify-content: center;
    height: 100px;
    width: 680px;
    margin: 0 auto;
    align-items: center;
    position: relative;
    .navbar {
        display: flex;
        align-items: center;
        border-left: 1px solid rgba(255, 255, 255, 0.2);
        height: 34px;
        width: 25%;
        padding-left: 20px;
        cursor: pointer;
        &:first-child {
            border-left: none;
            padding-left: 0;
        }
        .store-chip-text {
            font-size: 15px;
            font-weight: 700;
            color: var(--mainColor2);
            text-transform: uppercase;
            padding-left: 5px;
            letter-spacing: 1px;
        }
        img {
            height: 30px;
        }
    }
    &:before {
        content: '';
        width: 170px;
        height: 8px;
        background: #d9002c;
        position: absolute;
        bottom: 12px;
        transform: translateX(0);
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        left: 0;
        transition: 0.4s all ease-out;
        z-index: 1;
    }
}
.chip-navbar-tab-0 {
    &::before {
        transform: translateX(0);
    }
}
.chip-navbar-tab-1 {
    &::before {
        transform: translateX(100%);
    }
}
.chip-navbar-tab-2 {
    &::before {
        transform: translateX(200%);
    }
}
.chip-navbar-tab-3 {
    &::before {
        transform: translateX(300%);
    }
}
