.user_avatar_name {
    background: #54b76d;
    border-radius: 13px;
    width: 120px;
    padding: 2px 0;

    margin-top: 10px;
    img {
        position: absolute;
        bottom: 38px;
        left: 20px;
        z-index: 7;
        height: 80px;
    }
    .game_user_name {
        display: flex;
        font-weight: 700;
        //padding-top: 3px;
        justify-content: center;
        align-items: center;
        color: var(--cColor);
        font-size: 14px;
        height: 100%;
        padding: 2px 6px;
    }
}
