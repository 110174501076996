._3d-button {
    height: 48px;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--cColor);
    white-space: nowrap;
    padding: 0 40px;
    min-width: 142px;

    font: Condensed Bold 15px Mardoto;
    text-transform: uppercase;
    user-select: none;

    &.green {
        background: linear-gradient(to right, #4f5e18 0%, #36442c 100%);
        border-bottom: 3px solid #1c290d;
    }

    &.silver {
        background: #636b7f;
        border-bottom: 3px solid #0f1a36;
    }

    &.red {
        background: linear-gradient(180deg, #ad2125 0%, #871b24 100%);
        border-bottom: 3px solid #341e1d;
    }

    &.disabled-click {
        cursor: unset;
    }

    &:not(.disabled-click):active {
        margin-top: 3px;
        border-bottom: none;
    }

    .button-icon {
        max-width: 26px;
        max-height: 26px;
    }
}
