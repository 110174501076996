.lock-button {
    //width: 100%;
    height: 48px;
    background: linear-gradient(180deg, #ad2125 0%, #871b24 100%);
    border-bottom: 3px solid #341e1d;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding-left: 12px;
    border-radius: 5px;
    cursor: pointer;

    img {
        width: 24px;
        height: 24px;
    }

    .text-wrapper {
        display: flex;
        flex-direction: column;
        padding: 0 10px;
        border-right: 1px solid rgba(255, 255, 255, 0.4);
        text-align: left;

        .unlock-info {
            font: 200 12px/14px Mardoto;
            color: var(--cColor);
        }
    }

    .action-space {
        padding: 0 40px;
    }
}
