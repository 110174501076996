.new-badges,
.new-badges-animating {
    position: absolute;
    top: 0;

    width: 758px;
    height: 150px;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 10px;

    .new-badges-section {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 100%;
        margin-left: 20px;
        .badge-img {
            width: 120px;
            transform: scale(1);
        }
        .badge-img-animating {
            transition: all 0.4s ease-in-out;
            transform: scale(1.2, 1.2);
            width: 120px;
        }
        .new-badges-text,
        .new-badges-text-animating {
            display: flex;
            flex-direction: column;
            padding-top: 10px;
            padding-left: 20px;
            span {
                font-size: 15px;
                font-weight: 500;
                color: var(--mainColor2);
                text-transform: capitalize;
                padding-bottom: 5px;
            }
            p {
                font-size: 19px;
                font-weight: 700;
                color: var(--mainColor2);
                text-transform: uppercase;
                padding-bottom: 10px;
            }
            .new-badges-block {
                display: flex;
                justify-content: space-between;
                align-items: center;
                .new-badges-chip {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    span {
                        padding-top: 5px;
                        padding-left: 10px;
                        font-size: 21px;
                        font-weight: 700;
                    }
                    &:last-child {
                        margin-left: 10px;
                    }
                }
            }
        }
        .new-badges-text-animating {
            span,
            p,
            img {
                transition: all 0.4s ease-in-out;
                opacity: 0;
            }
        }
        .claim,
        .claim-animating {
            background: linear-gradient(to right, #0bac16 0%, #005a06 100%);
            width: 150px;
            height: 46px;
            box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
            position: absolute;
            right: 25px;
            margin-top: 40px;
            border-radius: 5px;
            cursor: pointer;
            .button-tree {
                z-index: 2;
                button {
                    width: 150px;
                    position: absolute;
                    height: 46px;
                    span {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        text-transform: uppercase;
                        font-size: 15px;
                        font-weight: 700;
                        letter-spacing: 1px;
                        color: var(--mainColor2);
                    }
                }
                .button-tree-under {
                    position: absolute;
                    width: 150px;
                    z-index: 1;
                    bottom: -6px;
                    background: #005a06;
                }
            }
        }
        .claim-animating {
            transition: all 0.2s ease-in-out;
            margin-left: 220px;
            opacity: 0;
        }
    }
}
.new-badges-animating {
    background: none;
    transition: all 0.4s ease-in-out;
}
