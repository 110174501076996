.battle-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .battle-item-image-wrapper {
        position: relative;
        width: 162px;
        height: 171px;

        .menu-item-image {
            width: 162px;
            height: 171px;
        }

        .additional-stars {
            position: absolute;
            bottom: 6px;
            right: 37px;
            background: #26334ee6;
            border: 1px solid #ffffff24;
            border-right: none;
            box-shadow: 0 3px 6px #00000029;
            border-top-left-radius: 15px;
            border-bottom-left-radius: 15px;
            padding: 5px 0 5px 18px;

            .additional-data {
                position: relative;
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                justify-content: center;
                margin-right: 10px;

                .stars-count {
                    color: var(--cColor);
                    .darker {
                        color: var(--rColor);
                    }
                }
            }

            .star-hex {
                position: absolute;
                top: -20px;
                right: -70px;
                height: 91px;
            }
        }
    }

    .battle-name-wrapper {
        margin-top: 20px;

        .standard-label {
        }
    }

    .bet-count {
        display: flex;
        align-items: center;
        justify-content: center;
        img {
            margin-right: 10px;
        }
    }

    .play-button-wrapper {
        margin-top: 20px;
        height: 53px;

        ._3d-button {
            padding: 0;
        }
    }

    .locked-level {
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(21, 35, 62, 1);
        height: 51px;
        border: 1px dashed rgba(255, 255, 255, 0.1);
        border-radius: 8px;
        padding: 0 20px;
        margin-top: 21px;

        .lock-icon {
            width: 24px;
            height: 24px;
        }

        .standard-label {
            margin-left: 7px;
        }
    }
}
