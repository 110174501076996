.static-gift {
    position: absolute;
    width: 90px;
    height: 90px;

    img {
        width: 100%;
    }

    &.seat-1 {
        bottom: 65px;
        left: 30px;
    }

    &.seat-4 {
        top: 215px;
        left: 760px;
    }

    &.seat-3 {
        bottom: 420px;
        left: 640px;
    }
}

.seat-2 {
    bottom: 270px;
    left: 150px;
}
