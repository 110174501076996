.user_image_info_1,
.user_image_info_2,
.user_image_info_3,
.user_image_info_4 {
    width: 120px;
    height: 124px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 1;

    .vip-user-game {
        position: absolute;
    }
    .user_game_block,
    .user-game-time-block,
    .user-game-vip-block {
        background: #57bb70;
        width: 90px;
        height: 90px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: -2px;
        &.user-game-vip-block {
            background: transparent;
        }
        .user_image_info_img,
        .user-img-info-img-block {
            width: 76px;
            height: 76px;
            border-radius: 50%;
            margin: 0 auto;
            border: 5px solid #88db9d;
            position: absolute;
            z-index: 5;

            img {
                width: 100%;
                height: 100%;
                border-radius: 50%;
            }
        }
        .user-img-info-img-block {
            border: 5px solid transparent;
        }
    }
    .user-game-time-block {
        background: #114223;
        transform: scale(1);
        border: 1px solid #095923;
    }
    .vip-user {
        position: absolute;
        z-index: 2;
        img {
            height: 90px;
        }
    }
    .vip-user-time {
        transform: scale(1.1);
    }
    .vip-ribbon {
        position: absolute;
        z-index: 7;
        bottom: 32px;
    }

    .contra-tooltip {
        .tooltip {
            .tooltip-suit {
                background: red;
                &:before {
                    border-right: 11px solid red;
                }
            }
            .contra-text {
                color: var(--mainColor2);
                text-transform: uppercase;
                font-size: 18px;
                font-weight: 700;
            }
        }
    }
}
.user_image_info_1 {
    left: 160px;
    bottom: 48px;
    .vip-user-game {
        top: 5px;
        z-index: 999;
    }
    .user-contra-btn {
        position: absolute;
        bottom: 55px;
        left: 5px;
        .button-tree {
            height: 46px;
            button {
                background: linear-gradient(to right, #ab0023 0%, #6a0000 100%);
            }
        }
    }
    .recontra-button-block {
        display: flex;
        width: 400px;
        height: 100px;
        position: absolute;
        left: -75px;
        bottom: -50px;
        .recontra-button {
            position: absolute;
            left: -7px;
            .button-tree {
                height: 46px;
                width: 206px;
                button {
                    margin-right: 10px;
                    width: 206px;
                    background: linear-gradient(
                        to right,
                        #ab0023 0%,
                        #6a0000 100%
                    );
                    span {
                        font-size: 15px;
                    }
                }
                .button-tree-under {
                    bottom: -6px;
                    width: 206px;
                }
            }
        }
        .pass-button {
            position: absolute;
            left: 207px;
            .button-tree {
                height: 46px;

                button {
                    background: linear-gradient(
                        to right,
                        #e9c69a 0%,
                        #c7996a 100%
                    );
                    margin-right: 10px;
                    width: 150px;
                    span {
                        font-size: 15px;
                        color: #000;
                    }
                }
                .button-tree-under {
                    background: #bc9164;
                    bottom: -6px;
                    width: 150px;
                }
            }
        }
    }
    .announce-user-button {
        height: 200px;
        background: transparent;
        width: 400px;
        position: absolute;
        left: 140px;
        bottom: 0px;
        .anounse-button-block {
            display: flex;
            width: 400px;
            height: 76px;
            position: absolute;
            left: 0;
            bottom: 0;
            .anounse-button {
                position: absolute;
                left: 15px;
                top: 12px;
                .button-tree {
                    height: 46px;
                    position: initial;

                    button {
                        margin-right: 10px;
                        height: 46px;
                        background: linear-gradient(
                            to right,
                            #0bac16 0%,
                            #005a06 100%
                        );
                        width: 206px;
                        span {
                            font-size: 15px;
                        }
                    }
                    .button-tree-under {
                        position: initial;
                        bottom: -6px;
                        background: linear-gradient(#0bac16 0%, #005a06 100%);
                        width: 206px;
                    }
                }
            }
            .cancel-button {
                left: 233px;
                top: 12px;
                position: absolute;

                height: 76px;
                .button-tree {
                    width: 150px;
                    position: initial;
                    button {
                        height: 46px;
                        width: 150px;
                        background: linear-gradient(
                            to right,
                            #e9c69a 0%,
                            #c7996a 100%
                        );
                        margin-right: 10px;
                        span {
                            font-size: 15px;
                            color: #000;
                        }
                    }
                    .button-tree-under {
                        background: #bc9164;
                        bottom: 23px;
                        width: 150px;
                        position: initial;
                    }
                }
            }
        }
    }

    .button-tree {
        width: 180px;
        height: 46px;
        position: absolute;
        left: 240px;

        button {
            background: linear-gradient(#ab0023 0%, #6a0000 100%);
            width: 180px;
            position: absolute;
            height: 46px;
            color: var(--mainColor2);
            text-transform: uppercase;
            &:hover {
                background: linear-gradient(#ab0023 0%, #6a0000 100%);
            }
            span {
                font-size: 15px;
            }
        }
        .button-tree-under {
            background: #400000;
            width: 180px;
            position: absolute;
            bottom: -6px;
        }
    }
}
.user_image_info_2 {
    left: 38px;
    top: calc(50% - 62px);
}
.user_image_info_3 {
    top: 20px;
    left: calc(50% - 45px);
}
.user_image_info_4 {
    right: 38px;
    top: calc(50% - 62px);
}
.userinfo-open {
    height: 620px;
    position: absolute;
    top: -30px;
    left: 15px;
}

.user-timing {
    background: var(--mainColor6);
    width: 76px;
    height: 76px;
    position: absolute;
    border-radius: 50%;
    top: 0px;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    z-index: 1;
    .spector-chart {
        position: absolute;
        left: -50px;
    }
}
.user-avatar-vip {
    width: 72px;
    z-index: 5;
    position: absolute;
    top: 10px;
    left: 25px;
    right: 0;
    bottom: 0;
}
.user-avatar-scale {
    transform: scale(1.1);
}
.user-timing-block {
    z-index: 4;
}
