.suit-button {
    width: 63px;
    height: 63px;
    border-radius: 50%;
    border: 1px solid var(--mainColor1);
    display: flex;
    align-items: center;
    font-size: 36px;
    justify-content: center;
    background: var(--mainColor7);

    cursor: pointer;
    &:hover {
        border: 3px solid var(--mainColor1);
    }
    .icon-Spade-icon,
    .icon-Clubs-icon,
    .icon-A {
        color: var(--mainColor1);
    }
}
.suit-button-selected {
    width: 63px;
    height: 63px;
    border-radius: 50%;
    border: 1px solid var(--mainColor1);
    display: flex;
    align-items: center;
    font-size: 36px;
    justify-content: center;
    background: var(--mainColor1);
}
