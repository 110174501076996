.user-info,
.user-info-game {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    position: absolute;
    left: 0;
    top: 0;
    z-index: 10;
    display: flex;

    .user-info-left-side {
        position: absolute;
        left: -295px;
        width: 360px;
        height: 620px;
        box-shadow: 7px 0 14px #000000d4;
        // background: linear-gradient(270deg, #97001f 0%, #6a0000 100%);
        background: linear-gradient(to right, #3b4c70 0%, #102248 100%);
        z-index: 2;
        // animation: userInfoOpen 0.3s both;
        transition: left 0.3s ease-in-out;
        .main-user-info {
            width: 100%;
            height: 267px;
            position: relative;
            .edit-username,
            .edit-username-lang {
                width: 128px;
                height: 22px;
                background: radial-gradient(#c1bcac 0%, #615e56 200%);
                border: 1px solid rgba(255, 255, 255, 0.3);
                border-bottom-left-radius: 3px;
                border-bottom-right-radius: 3px;
                position: relative;
                margin-left: 224px;
                z-index: 1;
                box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.6);
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                p {
                    color: var(--mainColor2);
                    font-size: 12px;
                    font-weight: 700;
                    letter-spacing: 0.5px;
                    padding-left: 8px;
                }
                img {
                    margin-top: 5px;
                }
                &.edit-username-lang {
                    width: 180px;
                    margin-left: 180px;
                }
            }

            .main-user-info-back {
                width: 360px;
                position: absolute;
                left: 0;
                top: 0px;
                height: 286px;
                opacity: 0.6;
            }
            .inner-wrapper {
                width: 100%;
                height: 267px;
                position: absolute;
                left: 0;
                top: 0;
                .user-pic-name {
                    width: 100%;
                    height: 166px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 0 15px;
                    box-sizing: border-box;
                    .user-img-wrapper {
                        height: 97px;
                        width: 97px;
                        position: relative;
                        border-radius: 50%;
                        background: #c1bcac;
                        img {
                            width: 97px;
                            height: 97px;
                            border-radius: 50%;
                            background: #c1bcac;
                        }
                        .level {
                            width: 30px;
                            height: 30px;
                            background: var(--mainColor2);
                            border-radius: 50%;
                            display: flex;
                            font-size: 13px;
                            align-items: center;
                            justify-content: center;
                            color: #010001;
                            position: absolute;
                            right: 0;
                            bottom: 0;
                        }
                    }
                    .user-additional {
                        margin-left: 10px;
                        text-align: left;
                        width: calc(100% - 97px);
                        .user-name {
                            font-size: 16px;
                            font-weight: 700;
                            color: #ffffff;
                            text-shadow: 0 0 6px #00000026;
                        }
                        .user-id {
                            font-size: 14px;
                            font-weight: 200;
                            color: #ffffff;
                            text-shadow: 0 0 6px #00000026;

                            .highlighted {
                                font-weight: bold;
                            }
                        }
                        .userinfo-level-bar {
                            margin-top: 10px;
                            background: #241f18;

                            padding: 0 4px;
                            height: 16px;
                            width: 100%;
                            border-radius: 5px;
                            position: relative;
                            box-sizing: border-box;
                            .userinfo-level-full {
                                height: 100%;
                                position: relative;
                                transition: width 0.5s ease-in-out;
                                border-radius: 6px;
                                background: linear-gradient(
                                    #ffffff 0%,
                                    #a2a2a2 100%
                                );
                                top: 0.2px;
                                left: -5px;
                                &.animating:after,
                                &.animating-vip:after {
                                    position: absolute;
                                    right: -2px;
                                    top: 2;
                                    width: 3px;
                                    height: 130%;
                                    margin-top: -15%;
                                    background: #ecdec1;
                                    filter: blur(1px);
                                }
                            }
                        }
                    }
                }
            }
        }
        .games-played-content {
            width: 100%;
            position: relative;
            top: -30px;
            display: flex;
            align-items: center;
            justify-content: space-around;

            .games-played {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                position: relative;
                top: -22px;
                left: -16px;
                .games-played-img {
                    width: 50px;
                    img {
                        width: 100%;
                    }
                }
                .games-played-text {
                    text-align: center;
                    color: var(--mainColor2);
                    font-weight: bold;
                    font-size: 16px;
                    padding-top: 10px;
                    padding-bottom: 10px;
                }
                .games-played-text-wrapper {
                    border-radius: 5px;

                    span {
                        color: var(--mainColor2);
                        text-align: center;
                        font-weight: bold;
                    }
                }
            }
            .win-rate {
                display: flex;
                align-items: center;
                justify-content: space-evenly;
                flex-direction: column;
                position: relative;
                top: -10px;
                right: -12px;
                .win-rate-block {
                    width: 60px;
                    height: 60px;
                    border-radius: 50%;
                    background: rgba(0, 0, 0, 0.4);
                    position: relative;
                    .win-rate-pie-chart {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        span {
                            position: absolute;
                        }
                    }
                }

                .win-rate-info {
                    display: flex;
                    flex-direction: column;
                    .rate-text {
                        color: var(--mainColor2);
                        font-size: 12px;
                        text-align: center;
                        font-weight: bold;
                        width: 100px;
                        padding-bottom: 10px;
                        padding-top: 10px;
                    }
                    .secondary-label {
                        color: var(--mainColor2);
                        width: 100px;
                        text-align: center;
                    }
                }
            }
        }
        .user-tournament-info {
            width: 360px;
            background: linear-gradient(
                to right,
                rgba(0, 0, 0, 0.47) 0%,
                rgba(162, 138, 104, 0) 100%
            );
            height: 50px;
            display: flex;
            align-items: center;
            margin-bottom: 10px;
            .tour-img-block {
            }
            .user-tournament-text {
                color: var(--mainColor2);
                font-weight: 700;
                padding-left: 10px;
            }
            .user-tournament-joined,
            .user-tournament-finished {
                p {
                    color: #fff;
                    font-family: 'DejaVu Sans';
                    font-weight: 700;
                    font-size: 15px;
                }
                span {
                    color: rgba(255, 255, 255, 0.5);
                    font-family: 'Mardoto';
                    font-weight: 700;
                    font-size: 12px;
                }
            }
            .user-tournament-joined {
                width: 100px;
                margin-left: 20px;
                border-right: 1px solid rgba(255, 255, 255, 0.05);
            }
            .user-tournament-finished {
                margin-left: 32px;
            }
        }
        .joined-tournament-block {
            .joined-tournament-content {
                width: 340px;
                margin-left: 10px;
                border: transparent;
                border-radius: 5px;
                height: 52px;
                display: flex;
                background: linear-gradient(to right, #4f5e18 0%, #36442c 100%);
                justify-content: space-between;
                align-items: center;
                margin-top: 38px;
                position: relative;
                z-index: 3;

                .joined-left {
                    width: 250px;
                    padding-left: 20px;
                    border-right: 1px dashed #fff;
                    p {
                        color: var(--mainColor2);
                        font-size: 12px;
                    }
                }
                .joined-right {
                    width: 100px;
                    cursor: pointer;
                    span {
                        color: var(--mainColor2);
                        font-size: 15px;
                        text-transform: capitalize;
                        text-align: center;
                        display: block;
                    }
                }
            }

            .joined-tournament-bottom {
                position: absolute;
                height: 52px;
                background: #313504;
                width: 340px;
                left: 10px;
                bottom: 18px;
                border-bottom-right-radius: 5px;
                border-bottom-left-radius: 5px;
                z-index: -1;
            }
        }
    }
    .user-info-team-game {
        position: absolute;
        left: -370px;
        height: 620px;
        width: 720px;
        background: linear-gradient(270deg, #97001f 0%, #6a0000 100%);
        transition: left 0.2s ease-in-out;
    }
    .empty-close {
        position: absolute;
        width: 280px;
        height: 620px;
        left: 720px;
        background: rgba(0, 0, 0, 0.5);
        cursor: pointer;
        // animation: userInfoClose 0.5s both;
    }
    &.open {
        .user-info-left-side {
            left: 0;
        }
        .right-side {
            left: 360px;
        }
    }
}
@keyframes userInfoOpen {
    0% {
        left: -295px;
    }
    100% {
        left: 0px;
    }
}
@keyframes userInfoClose {
    0% {
        left: -15px;
    }
    100% {
        left: -315px;
        width: 0px;
    }
}
.user-info-game {
    background: rgba(0, 0, 0, 0.3);
    .empty-close {
        left: 0;
        width: 1000px;
        background: rgba(0, 0, 0, 0.3);
    }
}
