.battles-fill-center {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.battles-section {
    margin-top: 14px;
    .tab-wrapper {
        width: 100%;
        display: flex;
        align-items: flex-start;
        padding: 0 15px 13px 15px;
        box-sizing: border-box;

        .tab-sizer {
            height: 100%;
            width: 100%;
        }
    }

    .tab-content {
        width: 100%;
        height: 346px;
        //overflow-y: auto;
        overflow-x: hidden;

        .table-with-reward {
            overflow-y: auto;

            &.reward-included {
                height: calc(100% - 49px);
            }

            .universal-table {
                height: calc(100% - 49px);
            }
        }

        .triangle-loader {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
        }

        .universal-table {
            tr.not-active {
                filter: grayscale(1);
            }
            td {
                .place {
                    text-align: center;
                    display: flex;
                    flex-direction: column;
                    margin-top: 3px;
                    align-items: center;
                    justify-content: center;

                    &.highlighted {
                        flex-direction: row;

                        .flag {
                            width: 13px;
                            height: 13px;
                            margin-top: 0;
                            margin-left: 2px;
                            margin-bottom: 1px;
                        }
                    }
                }

                .name-players {
                    .players-info {
                        display: flex;
                        align-items: center;

                        .secondary-label {
                            font-weight: normal;
                        }

                        .shield-icon {
                            width: 16px;
                            height: 16px;
                            margin-right: 5px;
                        }
                    }
                }

                .battle-data {
                    display: flex;
                    justify-content: center;

                    .game-count-wrapper {
                        display: flex;
                        flex-direction: column;
                        min-width: 60px;
                        margin-left: 10px;
                        position: relative;
                        justify-content: center;

                        .thin-bar {
                            margin-top: 2px;
                        }

                        .count-wrapper {
                            position: absolute;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            left: -55px;
                            opacity: 0;
                            top: 3px;
                            transition: opacity 0.3s linear, left 0.3s linear;

                            &.active {
                                left: 0;
                                opacity: 1;
                            }
                        }
                    }
                }

                .battle-data,
                .star-data {
                    .hex-flipper {
                        width: 40px;
                        height: 36px;
                        .hex-image {
                            width: 22px !important;
                            height: 26px !important;
                            left: 22px;
                            top: 20px;

                            &.main {
                                width: 36px !important;
                                height: 38px !important;
                                left: 0;
                                top: 0;
                            }
                        }
                    }
                }

                .star-data {
                    display: flex;
                    justify-content: center;

                    .star-chip-count-wrapper {
                        display: flex;
                        flex-direction: column;
                        min-width: 60px;
                        margin-left: 10px;
                        position: relative;
                        justify-content: center;

                        .count-wrapper {
                            position: absolute;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            left: -55px;
                            opacity: 0;
                            top: 3px;
                            transition: opacity 0.3s linear, left 0.3s linear;

                            &.active {
                                left: 0;
                                opacity: 1;
                            }
                        }
                    }
                }

                .user-personal {
                    position: relative;
                    display: flex;
                    align-items: center;
                    .user-img {
                        position: relative;
                        width: 50px;
                        height: 40px;

                        img {
                            width: 40px;
                            height: 40px;
                            border-radius: 50%;
                        }

                        .user-level {
                            position: absolute;
                            right: 0;
                            bottom: 0;
                            width: 20px;
                            height: 20px;
                            background: var(--cColor);
                            border-radius: 10px;
                            text-align: center;
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            span {
                                color: var(--gColor);
                                font-size: 10px;
                                font-family: 'Mardoto';
                                font-weight: bold;
                            }
                        }
                    }

                    .user-name {
                        display: flex;
                        flex-direction: column;
                        margin-left: 8px;
                        text-align: left;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        max-width: 135px;

                        .standard-label {
                            text-overflow: ellipsis;
                            overflow: hidden;
                        }

                        .position {
                            display: flex;
                            align-items: center;
                            text-align: left;

                            span {
                                font: Condensed Bold 13px 'Mardoto';
                                color: var(--vColor);
                                padding-bottom: 3px;
                            }

                            img {
                                width: 24px;
                                height: 24px;
                                margin-left: 6px;
                            }
                        }
                    }
                }
            }
        }
    }

    .bottom-bar {
        box-sizing: border-box;
        border-top: 1px dashed var(--qColor);
        padding: 11px 15px 0 15px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}
