.dashboard-content {
    background: rgba(0, 0, 0, 0.2);
    width: 485px;
    height: 388px;
    overflow-y: scroll;
    .content-line {
        display: flex;
        padding: 14px 0px;
        height: 80px;
        border-bottom: 2px solid #261f17;
        border-top: 1px solid #6c5d46;
        width: 430px;
        margin: 0 auto;
        &:first-child {
            border-top: 1px solid transparent;
        }
        .user-leaderboard-img-block {
            position: relative;
            .user-leaderboard-img {
                width: 52px;
                height: 52px;
                border-radius: 50%;
            }
            .user-leaderboard-level {
                width: 18px;
                height: 18px;
                border-radius: 50%;
                border: 1px solid #f8c48a;
                background: #430000;
                position: absolute;
                bottom: 0;
                right: 0;
                span {
                    color: #f6c58c;
                    font-size: 7px;
                    font-family: 'MardotoBold';
                    display: block;
                    text-align: center;
                    padding-top: 4px;
                }
            }
        }
        .user-position-block {
            width: 100px;
            text-align: center;
            padding-left: 15px;
            .user-position-name {
                font-weight: 500;
                color: var(--mainColor2);
                font-size: 18px;
            }
            .position-btn {
                width: 70px;
                height: 20px;
                margin-top: 5px;
                border-radius: 2px;
                background: linear-gradient(#030607 0%, #2d3946 100%);
                &.orange {
                    background: linear-gradient(#fff08b 0%, #fc9000 100%);
                }
                &.black {
                    background: linear-gradient(#030607 0%, #2d3946 100%);
                }
                &.grey {
                    background: linear-gradient(#d9d9d9 0%, #818181 100%);
                }
                &.bronze {
                    background: linear-gradient(#ffa743 0%, #9e4d0f 100%);
                }
                &.hardGrey {
                    background: linear-gradient(#818a8b 0%, #4b545b 100%);
                }
                span {
                    font-size: 18px;
                    font-family: 'MardotoBlack';
                    color: var(--mainColor2);
                    text-align: center;
                    display: block;
                }
            }
        }
        .user-score-block {
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-left: 20px;
            span {
                font-size: 12px;
                font-weight: 500;
                color: var(--mainColor2);
                text-transform: capitalize;
                margin-bottom: 5px;
            }
            p {
                font-weight: 700;
                color: var(--mainColor2);
                font-size: 18px;
            }
        }
        .user-game-count {
            display: flex;
            flex-direction: column;
            padding-top: 5px;
            text-align: center;
            margin-left: 20px;
            span {
                font-size: 16px;
                font-weight: 500;
                color: var(--mainColor2);
                text-transform: capitalize;
                margin-bottom: 10px;
            }
            .level-bar {
                background: #2c251c;
                border-radius: 4px;
                width: 48px;
                height: 8px;
                position: relative;
                min-width: 50px;
                border: none;

                .level-full {
                    background: linear-gradient(#ffffff 0%, #a2a2a2 100%);
                    width: 20px;
                    height: 6px;
                    position: absolute;
                    border-radius: 70%;
                    top: 1px;
                    left: 1px;
                }
            }
        }
        .tournament-followed-btn,
        .tournament-followed-follow {
            background: linear-gradient(#b59a74 0%, #504435 100%);
            box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.16);
            width: 130px;
            height: 43px;
            border-radius: 5px;
            margin-left: 35px;
            margin-top: 5px;
            cursor: pointer;
            span {
                color: var(--mainColor2);
                font-size: 16px;
                font-family: 'Roboto';
                text-align: center;
                display: block;
                padding-top: 10px;
            }
        }
        .tournament-followed-follow {
            background: #3e3830;
        }
    }
    .level-bar-vip {
        min-width: 40px;
    }
}
