.team-member-list-item {
    height: 70px;
    background: var(--tColor);
    margin-top: 13px;
    box-shadow: 0 3px 6px var(--fColor);
    border: 1px solid var(--uColor);
    border-radius: 10px;
    position: relative;
    display: flex;
    align-items: center;
    padding-left: 21px;
    cursor: pointer;

    &.rated {
        background: linear-gradient(to right, #516015 0%, var(--tColor) 20%);
        border-right: 0;
    }

    &:first-child {
        margin-top: 0;
    }

    .place-hex {
        width: 54px;
        height: 58px;
        position: absolute;
        left: -20px;
        top: 6px;
        display: flex;
        align-items: center;
        justify-content: center;

        .hexagon-info {
            position: absolute;
            left: 0;
            top: 0;
        }

        .standard-label {
            z-index: 1;
            padding-bottom: 4px;
        }
    }

    .user-content {
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;
        .user-personal {
            position: relative;
            display: flex;
            align-items: center;
            .user-img {
                position: relative;
                width: 60px;
                height: 44px;
                margin-left: 10px;

                img {
                    width: 44px;
                    height: 44px;
                    border-radius: 50%;
                }

                .user-level {
                    position: absolute;
                    right: 0;
                    bottom: 0;
                    width: 20px;
                    height: 20px;
                    background: var(--cColor);
                    border-radius: 10px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    span {
                        color: var(--gColor);
                        font-size: 10px;
                        font-family: 'Mardoto';
                        font-weight: bold;
                    }
                }
            }

            .user-name {
                display: flex;
                flex-direction: column;
                margin-left: 8px;
                text-align: left;

                .position {
                    display: flex;
                    align-items: center;
                    text-align: left;

                    span {
                        font: Condensed Bold 13px 'Mardoto';
                        color: var(--vColor);
                        padding-bottom: 3px;
                    }

                    img {
                        width: 24px;
                        height: 24px;
                        margin-left: 6px;
                    }
                }
            }
        }

        .user-stars {
            margin-right: 14px;
            display: flex;
            text-align: left;
            align-items: center;

            img.star {
                width: 36px;
                height: 36px;
            }

            .star-info {
                display: flex;
                flex-direction: column;
                margin-left: 10px;
            }

            .user-rating {
                display: flex;
                align-items: center;
                border-left: #ffffff0d 1px solid;
                margin-left: 10px;
                img.cup {
                    width: 56px;
                    height: 56px;
                }
            }
        }
    }
}
