.share-hex {
    display: flex;
    align-items: center;
    cursor: pointer;
    .share-text {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        text-align: right;
    }

    img {
        width: 65px;
        height: 69px;
        margin-left: 10px;
    }
}
