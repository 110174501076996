.join-team-panel {
    width: 100%;
    height: 80px;
    background: #192B51;
    box-shadow: 0 3px 25px #00000066;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .league-title {
        display: flex;
        align-items: center;
    }

    .search-wrapper {
        width: 485px;
    }

    .icons-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 20px;

        .vertical-line {
            height: 40px;
            width: 1px;
            background: #FFFFFF1A;
            margin: 0 20px;
        }

        .home-icon {
            cursor: pointer;
            width: 30px;
            height: 30px;
        }
    }
}