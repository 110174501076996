.blinking-plus {
    width: 56px;
    height: 56px;
    position: relative;
    cursor: pointer;

    .wave {
        border-radius: 50%;
        position: absolute;
        &.wave-1 {
            top: 0;
            left: 0;
            width: 56px;
            height: 56px;
            border: 0.1px solid rgba(255,255,255,.3);;
            background: rgba(255,255,255,.2);
            box-sizing: border-box;
            transform: scale(0.7);

            animation: pulse 1.5s infinite;
        }

        &.wave-2 {
            top: 7px;
            left: 7px;
            width: 42px;
            height: 42px;
            background: rgba(255,255,255,.3);
            transform: scale(0.7);

            animation: pulse 1.5s infinite;

        }
    }

    .plus-img {
        position: absolute;
        width: 30px;
        height: 30px;
        top: 13px;
        left: 13px;
    }
}

@keyframes pulse {
    0% {
        transform: scale(0.7);
    }

    70% {
        transform: scale(1);
    }

    100% {
        transform: scale(0.7);
    }
}