$wrapperWidth: 414px;

.team-notifications {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    z-index: 5;

    .empty-space {
        height: 100%;
        width: calc(100% - 414px);
        position: absolute;
        right: 0;
        top: 0;
        cursor: pointer;
    }

    .notifications-wrapper {
        position: absolute;
        left: -$wrapperWidth;
        top: 0;
        height: 100%;
        width: $wrapperWidth;
        background: linear-gradient(to right, #081227 0%, #172035 100%);

        transition: left 0.2s ease-in-out, right 0.2s ease-in-out;

        &.animated {
            left: 0;
        }

        .notification-title {
            text-align: left;
            padding: 25px 0;
            border-bottom: 1px solid #ffffff1a;
            margin-left: 20px;
        }

        .notification-items {
            padding: 10px 20px;
            overflow-y: auto;
            height: calc(100% - 100px);

            .notification-item {
                margin-top: 10px;

                &:first-child {
                    margin-top: 0;
                }
            }
        }
    }

    &.right {
        .notifications-wrapper {
            left: unset;
            right: -$wrapperWidth;

            &.animated {
                right: 0;
            }
        }

        .empty-space {
            right: unset;
            left: 0;
        }
    }
}

.external-notifications {
    position: absolute;
    left: -376px;
    bottom: 90px;
    width: 376px;
    z-index: 500;

    transition: left 0.3s ease-in-out;

    &.show {
        left: 20px;
    }
}
