.standard-fill-bar {
    width: 153px;

    display: flex;
    justify-content: center;

    &.fill {
        padding-right: 30px;
        box-sizing: content-box;
    }

    .fill-wrapper {
        width: 100%;
        height: 25px;
        background: #0e1936;
        border: 0.5px solid var(--pColor);
        border-top-left-radius: 15px;
        border-bottom-left-radius: 15px;
        box-sizing: border-box;
        position: relative;
        border-right: 0;

        padding: 2px;

        &.closed {
            border-right: 1px solid var(--pColor);
            border-top-right-radius: 15px;
            border-bottom-right-radius: 15px;
        }

        .loader-wrapper {
            width: 100%;
            position: relative;
            height: 100%;
            display: flex;
            align-items: center;
            text-align: center;

            .loader-inner-wrapper {
                height: 100%;
                width: 100%;
                border-radius: 15px;
                overflow: hidden;

                .loader-inner {
                    height: 100%;
                    border: 1px solid var(--pColor);
                    border-radius: 15px;
                    box-sizing: border-box;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }

            .loader-text {
                width: 100%;
                position: absolute;
                font: Condensed Bold 14px 'Mardoto';
                color: #ffffff;
                text-shadow: 0 0 6px var(--gColor);
            }
        }

        .fill-bar-img {
            position: absolute;
            width: 47px;
            height: 42px;
            background: url('../../../../assets/images/team-game/hexagons/progress-right.svg')
                center no-repeat;
            right: -42px;

            top: -11px;

            .fill-icon {
                position: absolute;
                right: 3px;
                top: 0;
                width: 25px;
                height: 35px;
            }
        }
    }
}
