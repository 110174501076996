.dead-body-user {
    width: 40px;
    height: 40px;
    position: relative;
    border: 1px solid var(--Color);
    background: var(--eColor);
    border-radius: 50%;
    box-sizing: content-box;

    img {
        width: 34px !important;
        height: 34px !important;
        position: absolute;
        top: 3px;
        left: 3px;
    }
}
