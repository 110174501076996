.tour-locked {
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(52, 44, 34, 0.4);
    width: 520px;
    margin-bottom: 10px;
    &:last-child {
        border-bottom: 1px solid transparent;
    }
    img {
        margin-bottom: 15px;
    }
    span {
        padding-left: 15px;
        color: var(--mainColor2);
        font-size: 16px;
        margin-bottom: 15px;
    }
}
