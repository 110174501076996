.back_lobby {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    position: relative;
    top: 75px;
    width: 106px;
    height: 44px;
    background: linear-gradient(
        to right,
        var(--mainColor30) 0%,
        var(--mainColor31) 100%
    );
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    box-shadow: 12px 0px 12px rgba(0, 0, 0, 0.16);
    cursor: pointer;
    span {
        text-transform: uppercase;
        color: var(--mainColor2);
        font-size: 15px;
        font-weight: 500;
        position: relative;
        right: 6px;
    }
}
