.floating-tab-changer {
    width: 100%;
    height: 80px;
    overflow-x: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    position: relative;

    &::-webkit-scrollbar {
        display: none;
    }

    .tabs {
        width: 100%;
        height: 80px;
        position: absolute;
        left: 0;

        .tab-img-wrapper {
            width: 48px;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 58px;
            float: left;

            &:first-child {
                margin-left: 0;
            }

            .tab-img {
                width: 44px;
                height: 44px;
                cursor: pointer;
                opacity: 0.5;

                transition: opacity 0.3s ease-in-out;

                &.active,
                &:hover {
                    opacity: 1;
                }
            }
        }

        .tab-text-wrapper {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 54px;
            float: left;
            cursor: pointer;

            &:first-child {
                margin-left: 0;
            }
        }

        .floating-tab-line {
            width: 48px;
            height: 4px;
            border-radius: 5px 5px 0 0;
            background: var(--cColor);
            position: absolute;
            bottom: 0;

            transition: left 0.2s ease-in-out, width 0.2s ease-in-out;
        }
    }
}
