.tournament-locked {
    position: absolute;
    left: 440px;
    top: 65px;
    height: 550px;
    width: 560px;
    .tour-info-text {
        margin-bottom: 38px;
        margin-top: 10px;
        p {
            color: var(--mainColor2);
            font-size: 21px;
            font-weight: 700;
            text-transform: uppercase;
            letter-spacing: 1px;
        }
        span {
            color: var(--mainColor2);
            font-size: 16px;
            font-weight: 500;
            text-transform: capitalize;
        }
    }
    .tournament-locked-button {
        display: flex;
        background: #84745d;
        border-radius: 5px;
        margin-right: 16px;
        height: 46px;
        margin-top: 40px;
        position: relative;
        z-index: 2;

        .tour-locked-level {
            display: flex;
            margin-left: 12px;
            align-items: center;

            img {
                width: 24px;
            }
            .tour-locked-block {
                padding-left: 10px;
                display: flex;
                flex-direction: column;
                span {
                    font-size: 15px;
                    color: var(--mainColor2);
                    font-weight: 700;
                }
                p {
                    font-size: 11px;
                    color: var(--mainColor2);
                    font-weight: 500;
                }
            }
        }
        .tour-locked-border {
            border: 1px solid rgba(255, 255, 255, 0.4);
            height: 36px;
            margin-top: 4px;
            margin-left: 50px;
        }
        .tour-locked-close {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 auto;
            cursor: pointer;

            span {
                font-size: 15px;
                color: var(--mainColor2);
                font-weight: 700;
                text-transform: uppercase;
            }
        }
    }
    .tournament-locked-bg {
        position: absolute;
        height: 50px;
        background: #3b281f;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
        width: 544px;
        bottom: 24px;
        z-index: 1;
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 5px;
    }
}
