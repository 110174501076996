.team-info-list-item {
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    margin-top: 28px;

    &:last-child {
        margin-bottom: 28px;
    }

    .side {
        height: 116px;
        border-radius: 9px;
        box-shadow: 0 3px 6px #00000029;
        background: #14264c;
        border: 1px solid #ffffff1a;
    }

    .left-side {
        width: 35%;
        display: flex;

        .shield-wrapper {
            width: 80px;
            height: 100%;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 16px;

            img {
                width: 100%;
            }
        }

        .info-section {
            width: calc(100% - 115px);
            display: flex;
            flex-direction: column;

            padding: 14px 14px 22px 0;

            .top-info {
                width: 100%;
                display: flex;
                flex-direction: column;
                text-align: left;
            }

            .bottom-info {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                justify-content: center;

                .bottom-info-inner {
                    margin-top: 14px;
                }

                .users-count {
                    display: flex;
                    align-items: center;
                    width: 100%;

                    img {
                        width: 14px;
                        height: 14px;
                    }
                }
            }
        }
    }

    .right-side {
        width: 63%;
        padding: 20px;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;

        .join-info-wrapper {
            width: 50%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            &>.standard-label {
                white-space: nowrap;
            }

            .join-info {
                display: flex;
                margin-top: 22px;
                align-items: flex-start;

                .more-info {
                    flex-direction: column;
                    justify-content: flex-end;
                    padding: 0 20px;
                    border-right: 1px solid #ffffff0d;
                    border-left: 1px solid #ffffff0d;

                    &:first-child {
                        padding-left: 0;
                        border-left: none;
                    }

                    &:last-child {
                        border-right: none;
                        padding-right: 0;
                    }

                    .join-info-top {
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        img {
                            width: 14px;
                            height: 14px;
                            margin-right: 4px;
                        }
                    }

                    .join-info-bottom {
                        span {
                            white-space: nowrap;
                        }
                    }
                }
            }
        }

        .button-with-condition {
            width: 100%;
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;

            .unavailable-text {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
            }

            ._3d-button {
                padding: 0;
            }
        }
    }
}
