.tour-rewards-tooltip {
    background: var(--mainColor2);
    position: absolute;
    animation: tourRewards 1s ease-in-out;
    border-radius: 10px;
    border-top-right-radius: 0px;
    padding: 6px;
    width: 115px;
    left: -145px;
    top: 35px;
    z-index: 999;
    p {
        font-size: 12px;
        font-weight: 500;
        line-height: 14px;
        display: block;
    }
}
@keyframes tourRewards {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}
