.badge-images {
    margin-bottom: 10px;
    animation: badgeAnimationFade 0.6s;
    img {
        width: 100px;
        margin: 0 15px;
    }
}
.badge-animate {
    width: 100px;
    animation: imgScale 1.6s ease-in-out;
    z-index: 10;
}
@keyframes imgScale {
    0% {
        width: 100px;
        transform: scale(0);
        opacity: 0;
    }

    65% {
        width: 100px;
        transform: scale(1.3);
        opacity: 1;
    }

    90% {
        width: 100px;
        transform: scale(1);
        opacity: 1;
    }
    100% {
        width: 100px;
        transform: scale(1);
        opacity: 1;
    }
}
@keyframes badgeAnimationFade {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
