#join-team {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .join-team-content {
        height: calc(100% - 80px);
        background: rgba(0, 0, 0, 0.3);
        overflow-y: auto;

        .loader-wrapper {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .error-wrapper {
            box-sizing: border-box;
            padding: 20px 20px 0 20px;
        }
    }
}
