.close-button {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: var(--cColor);
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        width: 20px;
        height: 20px;
        cursor: pointer;
    }
}
