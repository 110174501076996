.chat-message-list-item {
    width: 100%;
    padding: 13px 0;
    border-bottom: 1px solid var(--uColor);

    &:last-child {
        margin-bottom: 30px;
    }

    .inner-wrapper {
        width: 100%;
        min-height: 44px;
        padding: 12px 15px;
        display: flex;
        box-sizing: border-box;
        flex-direction: column;

        &.joined {
            background: linear-gradient(to right, #516015 0%, #35442c 100%);
            box-shadow: 0 3px 6px #00000029;
            border-radius: 10px;
        }

        &.left {
            background: linear-gradient(
                to right,
                rgba(193, 188, 172, 0.24) 0%,
                rgba(97, 94, 86, 0.51) 100%
            );
            box-shadow: 0 3px 6px #00000029;
            border-radius: 10px;
        }

        .top-wrapper {
            display: flex;
        }

        .date-wrapper {
            text-align: right;

            span {
                font-family: Mardoto;
                font-size: 13px;
                font-weight: 200;
                color: var(--cColor);
            }
        }
    }

    .user-img {
        width: 44px;
        height: 44px;
        border-radius: 50%;
    }

    .text-section {
        margin-left: 16px;
        display: flex;
        flex-direction: column;
        min-height: 44px;
        text-align: left;
        width: 100%;

        p.message {
            margin: 14px 0 0 0;
            padding: 0;
            font: 350 16px/19px Mardoto;
            color: var(--cColor);
            //width: 236px;
        }
    }
}
