.team-button {
    outline: none;
    font: Condensed Bold 21px 'Mardoto';
    color: var(--cColor);
    padding: 10px 10px;
    border-radius: 5px;
    text-transform: uppercase;
    cursor: pointer;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    .btn-icon {
        padding-top: 3px;
        width: 40px;
        height: 40px;
        margin-right: 5px;
    }

    &.red {
        border: 1px solid #ffffff57;
        background: linear-gradient(to right, #ad2125 0%, #871b24 100%);
    }

    &.blue {
        height: 64px;
        width: 100% !important;
        border-radius: 10px;
        background: radial-gradient(
            ellipse at center,
            #3b4c70 0%,
            #102248 100%
        );
    }

    &.green {
        height: 64px;
        width: 100% !important;
        border-radius: 10px;
        background: linear-gradient(to right, #516015 0%, #35442c 100%);
    }
}
