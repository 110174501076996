.main-tab-changer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .main-tabs-wrapper {
        width: 100%;
        height: 52px;
        background: var(--sColor);
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        .detached {
            top: 0;
            position: absolute;
            height: 100%;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            transition: left 0.2s ease-in-out;
        }

        .changer-tab {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            z-index: 1;
            cursor: pointer;
        }
    }

    .tab-content {
        width: 100%;
        height: calc(100% - 67px);
        box-sizing: border-box;
    }
}
