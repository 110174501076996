.button-tree {
    border-radius: 5px;
    button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 140px;
        height: 46px;
        background: linear-gradient(to right, #0bac16 0%, #005a06 100%);
        border-radius: 5px;
        position: relative;
        z-index: 2;
        cursor: pointer;
        span {
            display: block;

            font-weight: 700;
            font-size: 22px;
            color: var(--mainColor2);
            text-transform: uppercase;
        }
    }
    &:active {
        border-bottom: none;
        margin-top: 4px;
        height: 56px;
    }

    .button-tree-under {
        height: 52px;
        background: #313504;
        width: 140px;
        position: absolute;
        z-index: 1;
        bottom: 12px;
        border-radius: 5px;
        &:active {
            height: 0;
            background: none;
        }
    }
}
