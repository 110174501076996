.round-score-line {
    display: flex;
    justify-content: space-around;

    .round-score-one-line {
        .round-score-text {
            width: 192px;
            padding: 8px 0;
            background: rgba(2, 67, 13, 1);
            border-radius: 5px;
            text-align: center;
            margin-bottom: 2px;
            color: var(--mainColor2);
            font-size: 16px;
            font-family: 'Roboto';
            font-weight: 700;
            text-transform: uppercase;
        }
        .round-score-empty {
            width: 192px;
        }
        .round-score-item {
            width: 150px;
            padding: 7px;
            text-align: center;
            background: rgba(2, 67, 13, 1);
            border-radius: 5px;
            margin-bottom: 2px;
            color: var(--mainColor2);
            font-size: 19px;
            font-family: 'Roboto';
            font-weight: 700;
            text-transform: uppercase;
        }
    }
}
