$app_width: 1000px;
$app_height: 620px;

.app {
    width: $app_width;
    height: $app_height;
    margin: 0 auto;
    position: relative;
    .page {
        width: 100%;
        height: 100%;
    }
}
.team-game-inner {
    width: $app_width;
    height: $app_height;
    border-radius: 10px;
    line-height: normal;
    background: #3b4c70;
    z-index: 100;
    .user-panel {
        opacity: 0;
    }
    ::-webkit-scrollbar {
        width: 7px;
        border-radius: 10px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: #a2a2a2;
        border-radius: 10px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: rgba(255, 255, 255, 0.6);
        border-radius: 10px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: rgba(255, 255, 255, 0.9);
    }
}
