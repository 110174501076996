.wheel-page {
    .background {
        .background_pattern {
            background-position: 45px -460px;
            .daily-bonus {
                display: flex;
                justify-content: space-between;
                margin-top: 33px;
                p {
                    font-size: 28px;
                    color: var(--mainColor2);
                    text-transform: uppercase;
                    padding-left: 26px;
                }
                .daily-chip {
                    width: 138px;
                    height: 36px;
                    background: #430000;
                    border-radius: 18px;
                    margin-right: 38px;
                    display: flex;
                    justify-content: space-around;
                    align-items: center;
                    span {
                        font-size: 19px;
                        font-weight: 700;
                        color: var(--mainColor2);
                    }
                }
            }
            .wheel-wrapper {
                position: absolute;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                z-index: 2;
                background-image: url('../../assets/images/wheel/wheel-bg.png');
                background-repeat: no-repeat;
                background-position: center;

                .wheel-cracks {
                    width: 400px;
                    height: 400px;
                    background: url('../../assets/images/wheel/wheel-frame.png');
                    background-size: cover;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    .big-elipse {
                        position: absolute;
                        background: url('../../assets/images/wheel/wheel-elipse.svg');
                        background-size: cover;
                        width: 404px;
                        height: 404px;
                        z-index: -1;
                    }
                    .wheel-bg {
                        width: 358px;
                        height: 358px;
                        background: url('../../assets/images/wheel/wheel-elipse.svg');
                        background-size: cover;
                        background-position: center;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        .wheel-icon {
                            position: absolute;
                            width: 100px;
                            height: 100px;
                            visibility: visible;
                            z-index: 2;
                        }
                        .wheel-arrow {
                            position: absolute;
                            width: 173px;
                            height: 107px;
                            z-index: 2;
                            left: 285px;
                        }
                    }
                    .spin-bottom {
                        position: absolute;
                        bottom: 95px;
                        z-index: -1;
                    }
                }
                .spin-img-rotate {
                    position: absolute;
                    top: -12px;
                    right: 188px;
                }
                .spin-wrapper {
                    position: relative;
                    background: #3d0004;
                    width: 150px;
                    left: 442px;
                    height: 150px;
                    border-radius: 50%;
                    top: -274px;
                    .spin-bg {
                        background-image: url('../../assets/images/wheel/wheel-frame.png');
                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: 140px 140px;
                        position: relative;
                        top: -75px;
                        height: 300px;
                        .spin-text-bg {
                            background-image: url('../../assets/images/wheel/spin-bg.png');
                            background-repeat: no-repeat;
                            background-position: center center;
                            position: relative;
                            height: 300px;
                            span {
                                position: absolute;
                                top: 130px;
                                left: 40px;
                                color: var(--mainColor2);
                                font-size: 29px;
                                text-transform: uppercase;
                            }
                        }
                    }
                }
            }
        }
    }
}
